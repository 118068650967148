/* stylelint-disable color-hex-length */
/* stylelint-disable scss/no-global-function-names */
/* stylelint-disable scss/at-else-closing-brace-space-after */
/* stylelint-disable scss/at-else-closing-brace-newline-after */
/* stylelint-disable scss/at-else-empty-line-before */
/* stylelint-disable scss/operator-no-unspaced */
/* stylelint-disable scss/at-if-closing-brace-space-after */
/* stylelint-disable scss/at-if-closing-brace-newline-after */
// --------------------------------------------------
// Media Query Mixin
// By default uses min width breakpoints
// Change to max width if needed
// --------------------------------------------------
@use "sass:color";
@use "sass:math";
@use "sass:string";
@use "variables" as v;
@use "functions" as f;

/*
    Based on Tailwind CSS breakpoints
    sm: 640px
    md: 768px
    lg: 1024px
    xl: 1280px
    xxl: 1536px
*/

@mixin screen($screen-size) {
    @if $screen-size =="xs" {
        @media (max-width: (v.$sm - 1px)) {
            @content;
        }
    }

    @else if $screen-size =="sm" {
        @media (min-width: (v.$sm)) {
            @content;
        }
    }

    @else if $screen-size =="md" {
        @media (min-width: (v.$md)) {
            @content;
        }
    }

    @else if $screen-size =="lg" {
        @media (min-width: (v.$lg)) {
            @content;
        }
    }

    @else if $screen-size =="xl" {
        @media (min-width: (v.$xl)) {
            @content;
        }
    }

    @else if $screen-size =="xxl" {
        @media (min-width: (v.$xxl)) {
            @content;
        }
    }

    @else if $screen-size =="xxl" {
        @media (min-width: (v.$xxxl)) {
            @content;
        }
    }

    @else {
        @media (min-width: ($screen-size)) {
            @content;
        }
    }
}

// --------------------------------------------------
// Fonts
// --------------------------------------------------
@mixin font-size($size: 16, $weight: 400, $line-height: normal, $letter-spacing: normal) {
    font-size: f.px2rem($size);
    font-weight: $weight;

    @if $line-height ==normal {
        line-height: normal;
    }

    @else {
        line-height: $line-height;
    }

    @if $letter-spacing ==normal {
        letter-spacing: normal;
    }

    @else {
        letter-spacing: #{math.div($letter-spacing * $size, 100)}px;
    }
}

@mixin font-size-without-size($size: 16, $weight: 400, $line-height: normal, $letter-spacing: normal) {
    // font-size: f.px2rem($size);
    font-weight: $weight;

    @if $line-height ==normal {
        line-height: normal;
    }

    @else {
        line-height: $line-height;
    }

    @if $letter-spacing ==normal {
        letter-spacing: normal;
    }

    @else {
        letter-spacing: #{math.div($letter-spacing * $size, 100)}px;
    }
}

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: string.quote($font-name);
        font-style: $style;
        font-weight: $weight;
        src:
            url($path + ".woff2") format("woff2"),
            url($path + ".woff2") format("woff2");
    }
}

// Image mixins
@mixin image-responsive($display: block) {
    display: $display;

    max-width: 100%;
    height: auto;
}

// Grid mixin
@mixin grid($rowGap: 0) {
    display: grid;

    grid-template-columns: repeat(4, minmax(auto, 1fr));

    column-gap: f.px2rem(24);
    row-gap: $rowGap;

    @include screen(v.$sm) {
        grid-template-columns: repeat(12, minmax(auto, 1fr));

        column-gap: f.px2rem(40);
    }
}

// Border Radius
@mixin border-radius($radius) {
    background-clip: padding-box;

    /* stops bg color from leaking outside the border: */
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

// Text truncate
@mixin text-truncate {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
}

// Flex toolkit
@mixin flex-center($direction: row) {
    display: flex;

    flex-direction: $direction;

    align-items: center;
    justify-content: center;
}

@mixin columns($columns: 1, $gap: 1rem) {
    grid-template-columns: repeat($columns, 1fr);

    gap: $gap;
}

@mixin drop-shadow {
    box-shadow: 0 0 8px 0 v.$blackish-20;
}

@mixin card-shadow {
    box-shadow: 0 0 48px 0 #11111114;
}

@mixin backdrop-blur {
    backdrop-filter: blur(30px);
}

@mixin backdrop-blur-2 {
    backdrop-filter: blur(60px);
}

@mixin responsive-font($min-font-size, $max-font-size) {
    font-size: clamp(#{$min-font-size}px,
        calc(#{$min-font-size}px + ($max-font-size - $min-font-size) * ((100cqi - 375px) / (1200 - 375))),
        #{$max-font-size}px);

    // font-size: clamp(38px, calc(38px + (64 - 38) * ((100cqi - 375px) / (1200 - 375))), 64px)
}

@mixin side-gradients($bgColor: v.$white) {

    /* stylelint-disable-next-line rule-empty-line-before */
    &::after,
    &::before {
        position: absolute;

        display: block;

        width: 10%;
        height: 100%;

        // height: calc(100% - f.px2rem(100));

        content: "";

        @include screen(xl) {
            width: 10%;

            // height: calc(100% - f.px2rem(84));
            // width: calc((100vw - f.px2rem(1200))/2 + f.px2rem(100));
        }

        // @include screen(xxl) {
        //     width: calc((100vw - f.px2rem(1200))/2 + f.px2rem(100));
        // }
    }

    &::after {
        top: 0;
        right: 0;
        z-index: 1;

        background: linear-gradient(to left,
                var(--bg-color) 0%,
                transparent 100%);
    }

    &::before {
        top: 0;
        left: 0;
        z-index: 1;

        background: linear-gradient(to right,
                var(--bg-color) 0%,
                transparent 100%);
    }
}

@mixin side-gradients-marquee($bgColor: v.$white) {

    /* stylelint-disable-next-line rule-empty-line-before */
    &::after,
    &::before {
        position: absolute;

        display: block;

        width: 10%;
        height: 100%;

        content: "";

        @include screen(xl) {
            width: 10%;
        }

        @include screen(xxl) {
            width: 10%;
        }
    }

    &::after {
        top: 0;
        right: 0;
        z-index: 1;

        background: linear-gradient(to left,
                var(--bg-color) 0%,
                transparent 100%);
    }

    &::before {
        top: 0;
        left: 0;
        z-index: 1;

        background: linear-gradient(to right,
                var(--bg-color) 0%,
                transparent 100%);
    }
}

@mixin gradient($bgColor: v.$white, $direction: to bottom, $opacity: 100%, $size: 50%) {

    /* stylelint-disable-next-line rule-empty-line-before */
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: block;

        width: 100%;
        height: 100%;

        content: "";

        background: linear-gradient($direction, $bgColor 0%, $bgColor $size, transparent $opacity);
    }
}

@mixin layout-padding {
    padding: 0 f.px2rem(24);

    @include screen(sm) {
        padding: 0 f.px2rem(40);
    }
}

@mixin tag-styles {
    padding: f.px2rem(6) f.px2rem(10);

    text-transform: uppercase;

    border: 1px transparent solid;
    border-radius: v.$border-radius;

    @include backdrop-blur;
}