/* stylelint-disable no-descending-specificity */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.form {
    .form-input-group {
        opacity: 0;
    }

    .full-name {
        display: flex;

        gap: 1rem;

        justify-content: space-between;

        .form-input-group-wrapper {
            width: 100%;
        }
    }

    .form-btn {
        opacity: 0;

        transform: translate(0, 20px);

        .btn {
            display: block;

            margin: 4rem auto;
        }
    }

    @include m.screen(sm) {
        grid-column: 4 / 10 !important;
    }

}

.form-disclaimer {
    text-align: center;

    opacity: 0;

    transform: translate(0, 20px);

    a {
        text-decoration: underline;
    }
}

.custom-filter {
    position: relative;
    
    &:hover {        
        .safari-caret {
            path {
                stroke: v.$blackish;
            }
        }
    }
    
}

.safari-caret {
    position: absolute;
    top: 50%;
    right: 14px;
    
    display: none;
    
    transform: translateY(-50%)
}