@use "sass:string";
@use "../abstracts/functions" as f;
@use "../abstracts/mixins" as m;

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src:
        url("../fonts/Inter-Black.woff2") format("woff2"),
        url("../fonts/Inter-Black.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    src:
        url("../fonts/Inter-ExtraBold.woff2") format("woff2"),
        url("../fonts/Inter-ExtraBold.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    src:
        url("../fonts/Inter-Bold.woff2") format("woff2"),
        url("../fonts/Inter-Bold.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src:
        url("../fonts/Inter-SemiBold.woff2") format("woff2"),
        url("../fonts/Inter-SemiBold.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    src:
        url("../fonts/Inter-Regular.woff2") format("woff2"),
        url("../fonts/Inter-Regular.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src:
        url("../fonts/Inter-Medium.woff2") format("woff2"),
        url("../fonts/Inter-Medium.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src:
        url("../fonts/Inter-Light.woff2") format("woff2"),
        url("../fonts/Inter-Light.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src:
        url("../fonts/Inter-ExtraLight.woff2") format("woff2"),
        url("../fonts/Inter-ExtraLight.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src:
        url("../fonts/Inter-Thin.woff2") format("woff2"),
        url("../fonts/Inter-Thin.woff") format("woff");
    font-display: swap;
}

// Fluid Fonts
// https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=38&fluidSize=3&relativeSize=1.5&maxSize=64

@mixin title-hero {
    @include m.font-size(38, 500, 1.1, -1);

    @include m.screen(lg) {
        @include m.font-size(64, 500, 1.1, -2);
    }
}

@mixin title-hero-clamp {
    @include m.font-size-without-size(38, 500, 1.1, -1);

    @include m.screen(lg) {
        @include m.font-size-without-size(64, 500, 1.1, -2);
    }

    font-size: clamp(2.375rem, 3vw + 1.5rem, 4rem);
}

@mixin title-great-hero-clamp {
    @include m.font-size-without-size(99, 500, 1, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(99, 500, 1.1, -5);
    }

    font-size: clamp(3.438rem, 5vw + 2rem, 6rem);
}

@mixin title-hero-clamp-cq {
    @include m.font-size-without-size(38, 500, 1.1, -3);

    @include m.screen(lg) {
        @include m.font-size-without-size(64, 500, 1.1, -3);
    }

    @include m.responsive-font(38, 64);
}

@mixin paragraph-hero {
    @include m.font-size(14, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size(18, 400, 1.55, -1);
    }

    &:has(~ .paragraph-hero) {
        margin-bottom: f.px2rem(18);
    }
}

@mixin paragraph-hero-clamp {
    @include m.font-size-without-size(14, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size-without-size(18, 400, 1.55, -1);
    }

    font-size: clamp(0.875rem, 1vw + 0.5rem, 1.125rem);

    &:has(~ .paragraph-hero-clamp) {
        margin-bottom: f.px2rem(18);
    }
}

@mixin paragraph-hero-clamp-cq {
    @include m.font-size-without-size(14, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size-without-size(18, 400, 1.55, -1);
    }

    @include m.responsive-font(14, 18);

    &:has(~ .paragraph-hero-clamp-cq) {
        margin-bottom: f.px2rem(18);
    }
}

@mixin title-1 {
    @include m.font-size(30, 500, 1.1, -2);

    @include m.screen(lg) {
        @include m.font-size(48, 500, 1.1, -2);
    }
}

@mixin title-1-clamp {
    @include m.font-size-without-size(30, 500, 1.1, -1.4);

    font-size: clamp(1.875rem, 2vw + 1.25rem, 3rem);

    @include m.screen(lg) {
        @include m.font-size-without-size(99, 500, 1.1, -1.4);

        letter-spacing: -1.4px !important;
    }
}

@mixin title-1-clamp-cq {
    @include m.font-size-without-size(26, 500, 1.1, -2.999);

    @include m.screen(lg) {
        @include m.font-size-without-size(48, 500, 1.1, -2.999);
    }

    @include m.responsive-font(26, 48);
}

@mixin title-2 {
    @include m.font-size(24, 500, 1.1, -2);

    @include m.screen(lg) {
        @include m.font-size(40, 500, 1.1, -2);
    }
}

@mixin title-2-clamp {
    @include m.font-size-without-size(24, 500, 1.1, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(40, 500, 1.1, -2);
    }

    font-size: clamp(1.5rem, 1vw + 1.25rem, 2rem);
}

@mixin title-2-clamp-cq {
    @include m.font-size-without-size(24, 500, 1.1, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(40, 500, 1.1, -2);
    }

    @include m.responsive-font(24, 40);
}

@mixin title-3 {
    @include m.font-size(20, 500, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size(32, 500, 1.26, -2);
    }
}

@mixin title-3-clamp {
    @include m.font-size-without-size(20, 500, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(32, 500, 1.26, -2);
    }

    font-size: clamp(1.25rem, 1vw + 1rem, 2rem);
}

@mixin title-3-clamp-cq {
    @include m.font-size-without-size(20, 500, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(32, 500, 1.26, -2);
    }

    @include m.responsive-font(20, 32);
}

@mixin title-4 {
    @include m.font-size(18, 500, 1.2, -2);

    @include m.screen(lg) {
        @include m.font-size(24, 500, 1.2, -2);
    }
}

@mixin title-4-clamp {
    @include m.font-size-without-size(18, 500, 1.2, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(24, 500, 1.2, -2);
    }

    font-size: clamp(1.125rem, 1vw + 0.75rem, 1.5rem);
}

@mixin title-4-clamp-cq {
    @include m.font-size-without-size(18, 500, 1.2, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(24, 500, 1.2, -2);
    }

    @include m.responsive-font(18, 24);
}

@mixin title-5 {
    @include m.font-size(15, 600, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size(20, 500, 1.26, -2);
    }
}

@mixin title-5-clamp {
    @include m.font-size-without-size(15, 600, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(20, 500, 1.26, -2);
    }

    font-size: clamp(1rem, 1vw + 0.75rem, 1.25rem);
}

@mixin title-5-clamp-cq {
    @include m.font-size-without-size(15, 600, 1.26, -2);

    @include m.screen(lg) {
        @include m.font-size-without-size(20, 500, 1.26, -2);
    }

    @include m.responsive-font(16, 20);
}

@mixin paragraph {
    @include m.font-size(13, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size(16, 400, 1.55, -1);
    }

    &:has(~ .paragraph) {
        margin-bottom: 1rem;
    }
}

@mixin paragraph-clamp {
    @include m.font-size-without-size(13, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size-without-size(16, 400, 1.55, -1);
    }

    &:has(~ .paragraph-clamp) {
        margin-bottom: 1rem;
    }

    font-size: clamp(13px, 1vw + 0.5rem, 16px);
}

@mixin paragraph-clamp-cq {
    @include m.font-size-without-size(13, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size-without-size(16, 400, 1.55, -1);
    }

    @include m.responsive-font(13, 16);

    &:has(~ .paragraph-clamp-cq) {
        margin-bottom: 1rem;
    }
}

@mixin medium-text {
    @include m.font-size(13, 400, 1, 2);

    @include m.screen(lg) {
        @include m.font-size(14, 400, 1, 2);
    }
}

@mixin small-text {
    @include m.font-size(12, 400, 1.55, -1);

    @include m.screen(lg) {
        @include m.font-size(12, 400, 1.55, -1);
    }
}

@mixin tag {
    @include m.font-size(12, 500, 1, 2);

    text-transform: uppercase;

    @include m.screen(lg) {
        @include m.font-size(12, 500, 1, 2);
    }
}

@mixin button-primary-font-style {
    @include m.font-size(14, 500, 1, -1);

    @include m.screen(lg) {
        @include m.font-size(16, 500, 1, -1);
    }
}

@mixin button-secondary-font-style {
    @include m.font-size(14, 600, 1, 2);

    @include m.screen(lg) {
        @include m.font-size(16, 600, 1, 2);
    }
}

@mixin text-link-style {
    @include m.font-size-without-size(14, 400, 1, 2);

    font-size: clamp(0.75rem, 1vw + 0.5rem, 1rem);
    text-decoration: underline;

    @include m.screen(lg) {
        @include m.font-size-without-size(16, 400, 1, 2);
    }
}

@mixin button-tertiary-font-style {
    @include m.font-size(12, 400, 1.5, -2);

    @include m.screen(lg) {
        @include m.font-size(14, 400, 1, -2);
    }
}

@mixin button-menu-font-style {
    @include m.font-size(14, 600, 1, 0);

    @include m.screen(lg) {
        @include m.font-size(14, 600, 1, 0);
    }
}

@mixin button-menu-font-style-header {
    @include m.font-size(14, 500, 1, -1);

    @include m.screen(lg) {
        @include m.font-size(14, 500, 1, -1);
    }
}