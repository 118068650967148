/* stylelint-disable rule-empty-line-before */
@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

.grid {
    @include m.grid;
}


.component-container {
    position: relative;

    padding: f.px2rem(8) f.px2rem(16);

    // scale: 0.98;

    // TODO:
    // el error esta en el ultimo contenedor con esta clase en cada pagina.
    // el padding-y tiene la mitad del x justamente xq siempre se apilan 2 elementos.
    // en el caso del ultimo componente queda la mitad de padding justamente porque no se apila nada.
    // probar si con este selector y seteando el padding-y en 16 se soluciona.
    // &:last-of-type {
    //     padding-bottom: f.px2rem(16) !important;
    // }
    &.--image-background {
        .component-container-content {
            &:has(.simple-hero) {
                @include m.screen(sm) {
                    min-height: 100dvh;
                }
            }
        }
    }
}

.component-wrapper {
    position: relative;

    width: 100%;
    overflow: visible;

    border-radius: v.$border-radius;

    &>img,
    &>video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.component-container-bg-el {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: v.$border-radius;

    &.--floating {
        height: calc(100% - 10rem);
    }

    &.--desktop {
        display: none;
        @include m.screen(sm) {
            display: block;
        }
    }

    &.--mobile {
        display: block;
        @include m.screen(sm) {
            display: none;
        }
    }
}

.component-container-content {
    position: relative;

    width: 100%;
    height: 100%;
    overflow: hidden;

    border-radius: v.$border-radius;

    &:has(.footer) {
        border-radius: 0;
        margin-top: 0;
        
        @include m.screen(md) {
            margin-top: f.px2rem(8);
        }
    }
}

.component-layout {
    @include m.layout-padding;

    &.full-width {
        width: 100%;
        padding: 0 !important;
        overflow: hidden;
    }
}

.component-layout:has(.master-container) {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.component-layout-wrapper {
    &[class*="cols-"] {
        @include m.grid;

        max-width: f.px2rem(1200);
        margin: 0 auto;

        &.cols-1 {
            &>* {
                grid-column: 1 / -1;
            }
        }

        &.cols-2 {
            &>* {
                grid-column: 1 / -1;

                @include m.screen(sm) {
                    grid-column: span 6;
                }
            }
        }

        &.cols-3 {
            &>* {
                grid-column: 1 / -1;

                @include m.screen(sm) {
                    grid-column: span 4;
                }
            }
        }

        &.cols-4 {
            &>* {
                grid-column: span 2;

                @include m.screen(sm) {
                    grid-column: span 3;
                }
            }
        }

        &.cols-custom {
            &.--novedades {
                &>*:nth-of-type(1) {
                    grid-column: 1/-1;

                    @include m.screen(sm) {
                        grid-column: span 8;
                    }
                }

                &>*:nth-of-type(2) {
                    grid-column: 1/-1;

                    @include m.screen(sm) {
                        grid-column: span 4;

                        .image-wrapper {
                            aspect-ratio: 6 / 7.93;
                        }
                    }
                }
            }
        }
    }

    &.full-width {
        width: 100%;
    }
}

.component {
    @include m.grid;

    container-type: inline-size;
}

.component-container-type {
    container-type: inline-size;
}

.component-padding {
    @include m.layout-padding;
}

// Headless UI
#headlessui-portal-root {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

[class*='cols-'] {
    &:not(.cols-1) {

        /* stylelint-disable-next-line no-descending-specificity */
        >* {
            // Animations: set initial state
            opacity: 0;
        }
    }
}