@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.carousel {
    position: relative;

    .component-padding {
        position: relative;
    }

    .arrows-container {
        position: relative;
        z-index: 100;
        
        padding: 0 f.px2rem(16);

        @include m.screen(sm) {
            padding: 0 f.px2rem(40);
        }
    }

    .arrows-wrapper {
        max-width: 1200px;
        margin: 0 auto;
    }

    .splide {
        position: static;

        width: 100%;
    }

    .splide__track {
        position: static;

        max-width: f.px2rem(1200);
        margin: 0 auto;

        // padding: 2rem 0;
        overflow: visible;

        @include m.side-gradients(var(--bg-color));

        @include m.grid;

        .splide__list {
            grid-column: 1/-1;
        }
    }

    .splide__slide {
        &>* {
            height: 100%;
        }

        // i need this to let the card to the hover state
        padding: 0.5rem;
    }

    .splide__arrows {
        position: relative;
        z-index: 0;

        display: flex;

        gap: 1rem;

        justify-content: space-around;

        width: max-content;
        padding: .5rem;
        margin-top: 2rem;
        margin-right: 0;
        margin-left: auto;
        overflow: hidden;

        @include m.backdrop-blur;

        background-color: transparent;
        border-style: solid;
        border-width: 1px;
        border-radius: 999px;

        .chevron {
            width: 2.5rem;
            height: 2.5rem;

            @include m.backdrop-blur;

            cursor: pointer;

            border-width: 1px !important;
            border-radius: 999px;
            
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
    
                transform: translate(-50%, -50%);
                
            }
        }
    }

    .splide__arrow {
        border-radius: 999px;
    }

    .splide__progress {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;
    }

    .splide__progress__bar {
        width: 100%;
        height: 100%;
    }
}