@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.modal-filter-pill {
    width: max-content;

    // padding: 0.75rem 0 0.75rem 1rem;
    padding: 0.75rem 1rem;

    text-wrap: nowrap;

    cursor: pointer;

    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left center;
    border: 1px solid v.$blackish-10;

    // border-right: 14px solid transparent;
    border-radius: 20px;

    transition: v.$transition;

    &:hover {
        border: 1px solid v.$blackish;
    }
}

.filter-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: none;

    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 50%);
    opacity: 0;

    .backdrop {
        display: flex;

        align-items: flex-end;
        justify-content: center;

        width: 100%;
        height: 100%;

        @include m.screen("md") {
            align-items: center;
        }
    }

    .content {
        width: 100%;
        padding: 1rem;

        background-color: v.$white;
        border-radius: v.$border-radius;

        @include m.screen("md") {
            max-width: 375px;
        }
    }

    .content-header {
        display: flex;

        place-content: center space-between;
    }

    .icon-close {
        cursor: pointer;
    }

    .content-footer {
        display: flex;

        gap: .5rem;

        place-content: center;

        button {
            width: 100%;
            padding: f.px2rem(14) 0;

            text-align: center;

            cursor: pointer;

            border-style: solid;
            border-width: 1px;
            border-radius: 999px;

            &.dark {
                color: v.$white;

                background-color: v.$blackish;
                border-color: v.$blackish;
            }

            &.white {
                color: v.$blackish;

                background-color: v.$white;
                border-color: v.$blackish;
            }
        }
    }

    .content-body {
        display: flex;

        flex-wrap: wrap;

        gap: .5rem;

        margin: 40px 0;

        .filter-pill {
            margin: 0 .5rem .5rem 0;
        }

        .tag {
            padding: f.px2rem(12) f.px2rem(16);

            cursor: pointer;

            background-color: #F3F3F3;
            border-radius: 999px;

            transition: v.$transition;

            &.--selected {
                color: v.$white;

                background-color: v.$blackish;
            }
        }
    }
}