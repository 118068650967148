/* stylelint-disable no-descending-specificity */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.form-input-group {
    &-wrapper {
        position: relative;

        // height: f.px2rem(56);
        margin-bottom: 16px;

        // @include m.screen(sm) {
        //     height: f.px2rem(60);
        // }

        // @include m.backdrop-blur-2;
    }

    .error-tag {
        margin-top: .25rem;
        margin-left: f.px2rem(14);

        color: rgba(255, 77, 79, 100%) !important;
        text-transform: uppercase;
    }

    &.textarea {
        height: 180px;
        margin-bottom: 1rem;

        textarea {
            height: 180px;
            padding-top: f.px2rem(30);

            resize: vertical;
        }

        .form-input {
            &:not(:placeholder-shown) {
                &+label {
                    top: 12px;
                }
            }
            
            &:focus+label {
                top: f.px2rem(12);
            
                font-size: f.px2rem(12);
            
                transform: translateY(0);
            }
        }

        .form-input-label {
            top: 30px;
        }

        &:has(.error-tag) {
            margin-bottom: 2rem;
        }

    }
}

.form-input {
    position: relative;

    display: block;

    width: 100%;
    height: f.px2rem(56);
    padding: 1.5rem .25rem .25rem 0.875rem;
    margin: 0 auto;

    font-size: f.px2rem(13);

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: v.$border-radius;
    outline: none !important;

    @include m.backdrop-blur-2;

    transition: all .2s ease-in-out;

    @include m.screen(sm) {
        height: f.px2rem(60);

        font-size: 1rem;
    }

    &~.optional {
        position: absolute;
        top: 50%;
        right: f.px2rem(14);

        text-transform: uppercase;

        transform: translateY(-50%);
    }

    &:not(:placeholder-shown) {
        &+label {
            top: f.px2rem(12);

            font-size: f.px2rem(12);

            transform: translateY(0);
        }
    }

    &.--error {
        &+label {
            top: 38%;
        }

        &~.optional {
            top: 41%;
        }

        &:focus,
        &:not(:placeholder-shown) {
            &+label {
                top: f.px2rem(12);
            }
        }
    }
}

.form-input-select {
    width: 100%;
    height: f.px2rem(56);
    padding: .4rem .25rem .25rem 0.875rem;

    font-size: f.px2rem(13);

    appearance: none;

    border-radius: v.$border-radius;

    transition: all .2s ease-in-out;

    @include m.backdrop-blur-2;

    @include m.screen(sm) {
        font-size: f.px2rem(16);
    }

    &~svg {
        position: absolute;
        top: 50%;
        right: f.px2rem(14);

        transform: translateY(-50%);
    }

    &:focus-visible {
        outline: none;
    }

    &.--error {
        &~svg {
            top: 40%;
        }
    }

    // &:focus~svg {
    //     transform-origin: top;
    //     rotate: 180deg;
    // }
}

.form-input-label {
    position: absolute;
    top: 50%;
    left: f.px2rem(12);

    display: inline-block;

    height: 14px;
    padding: 0 4px;
    margin: 0 auto;

    font-size: f.px2rem(13);
    font-weight: 400;
    line-height: 1;
    text-align: left;

    cursor: text;

    transition: v.$transition;
    transform: translateY(-50%);

    @include m.screen(sm) {
        font-size: 1rem;
    }
}

input:focus+label {
    top: f.px2rem(12);

    font-size: f.px2rem(12);

    transform: translateY(0);
}

.toggle {
    display: inline-flex;

    flex-direction: row-reverse;

    align-items: center;
    justify-content: space-between;

    width: 100%;

    cursor: pointer;
}

.toggle-switch {
    position: relative;

    display: inline-block;

    width: 58px;
    height: 32px;

    vertical-align: middle;

    background: #cccccc;
    border-radius: 16px;

    transition: background 0.25s;

    &::before,
    &::after {
        content: "";
    }

    &::before {
        position: absolute;
        top: 4px;
        left: 4px;

        display: block;

        width: 24px;
        height: 24px;

        background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
        border-radius: 50%;

        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 25%);

        transition: left 0.25s;
    }

    .toggle:hover &::before {
        background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);

        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 50%);
    }

    .toggle-checkbox:checked+& {
        background: v.$blackish;

        &::before {
            left: 30px;
        }
    }
}

.toggle-checkbox {
    position: absolute;

    visibility: hidden;
}

.toggle-label {
    position: relative;
    top: 2px;

    font-weight: 600;

    // theme override
    color: v.$blackish !important;
}

.gl-form-asterisk {
    padding: 0;
    padding-left: 3px;

    color: #e32b2b;

    background-color: inherit;

}

.gl-form-asterisk::after {
    content: "*";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    margin: 0;

    -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}