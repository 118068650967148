@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.job-listing {
    @include m.screen(sm) {
        // layout override
        grid-column: 3/11 !important;
    }

    &-list {
        display: flex;

        flex-direction: column;

        gap: 1rem;

        height: max-content;

        .empty-state {
            display: flex;
    
            flex-direction: column;
    
            gap: 0.5rem;
    
            align-items: center;
    
            padding: 3.4375rem 0;

            border: 1px dashed v.$blackish-20;

            border-radius: 15px;
    
            >* {
                text-align: center;
            }
    
            @include m.screen(sm) {
                padding: 3.4375rem 0;
            }
        }
    }

    .upload-cv{
        width: max-content;
        margin: 4rem auto 1.5rem;
        
        a {
            text-decoration: underline;
        }
    }
}
