/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.header {
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 999;

    // width: 100%;
    padding-top: f.px2rem(24) !important;
    padding-bottom: f.px2rem(24) !important;

    &.--hovered {
        background-color: v.$white;
        box-shadow: 0 0 8px 0 v.$blackish-20;

        .--highlighted {
            color: v.$white !important;

            background-color: v.$blackish !important;

            transition: background-color .2s ease-in-out !important;

            &:hover {
                background-color: v.$blackish-80 !important;
            }
        }
    }

    &.--with-shadow {
        @include m.drop-shadow;
    }

    // Theme styling for the header
    &.--below-895,
    &.--above-895 {
        background-color: transparent;

        transition: background-color .3s ease;

        .menu-btn,
        .lang-selector {
            background-color: transparent;
        }

        &.--hovered {
            background-color: v.$white;

            .menu-btn,
            .lang-selector,
            .button-menu-header {
                color: v.$blackish;

                svg {
                    * {
                        stroke: v.$blackish;
                    }
                }
            }
        }
    }

    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;

        width: 100%;

        transition: top .5s ease-in-out;
    }

    // background-color: v.$white;

    .nav-mobile,
    .nav-desktop {
        grid-column: 1/-1;
    }

    .logo {
        display: block;
    }


    &.--below-895 {
        display: flex;

        flex-direction: column;

        .burger::before,
        .burger::after,
        .burger div {
            display: block;

            height: 2px;
            margin: .25rem 0;

            content: "";

            border-radius: 3px;

            transition: v.$transition;
        }


        .burger {
            width: 1.5rem;
            height: 1.5rem;

            cursor: pointer;

            &.--active::before {
                transform: translateY(6px) rotate(135deg);
            }

            &.--active::after {
                transform: translateY(-6px) rotate(-135deg);
            }

            &.--active div {
                transform: scale(0);
            }
        }

        .nav-mobile,
        .nav-mobile-controls {
            grid-column: 1/-1;

            @include m.layout-padding;
        }

        .nav-mobile-controls {
            display: flex;

            align-items: center;
            justify-content: space-between;

            padding: 0 1.5rem;

            .lang-and-burger,
            .lang-selector {
                display: flex;

                align-items: center;
                justify-content: space-between;
            }

            .lang-and-burger {
                gap: f.px2rem(18);
            }

            .lang-selector {
                gap: f.px2rem(4);
            }
        }

        .nav-mobile {

            /* stylelint-disable-next-line declaration-empty-line-before */
            margin-top: f.px2rem(40);
            margin-bottom: f.px2rem(24);

            &::-webkit-scrollbar {
                display: none;
            }

            &-wrapper {
                display: flex;

                flex-direction: column;
            }

            &-list {
                display: flex;

                align-items: center;
                justify-content: space-between;

                overflow-x: scroll;

                -ms-overflow-style: none;
                scrollbar-width: none;

                li {
                    display: flex;

                    align-items: center;
                    justify-content: center;

                    width: 100%;

                    button {
                        color: v.$blackish !important;

                        background-color: transparent;
                    }
                }
            }

            &-sublist {
                display: flex;

                flex-direction: column;

                gap: f.px2rem(18);

                overflow: scroll-y;
                scrollbar-color: black white;

                &:has(.--with-grid-item) {
                    height: 322px;
                    overflow-y: scroll;
                }

                .nav-link-title {
                    display: inline-flex;

                    gap: .5rem;

                    align-items: center;

                    margin-bottom: 1rem;

                    font-size: 1.125rem;
                }
            }
        }

        // tabs
        [data-el="tab-content-container"] {
            margin-top: f.px2rem(32);

            // overflow: hidden;
        }

        [data-el="tab-option"] {
            padding: f.px2rem(8);

            text-wrap: nowrap;

            border-radius: 999px;
        }

        [data-el="tab-content"] {
            position: relative;

            display: none;

            &.--show {
                display: block;
            }
        }

        // login
        .login {
            display: flex;

            gap: f.px2rem(8);

            justify-content: center;

            padding-top: f.px2rem(24);
            padding-right: f.px2rem(42);
            padding-left: f.px2rem(42);
            margin-top: auto;
            margin-bottom: f.px2rem(48);

            // margin-bottom: f.px2rem(64);

            border-top: 1px v.$blackish-10 solid;

            a {
                width: 100%;
                padding: f.px2rem(14) 0;

                text-align: center;

                border-style: solid;
                border-width: 1px;
                border-radius: 999px
            }

            .divider {
                display: none;
            }
        }

        .menu-item {
            .item-with-description {
                &:has(.disabled) {
                    cursor: not-allowed;
                }

                .button-menu-header {
                    display: flex;

                    flex-direction: column;

                    gap: f.px2rem(2);

                }
            }
        }

        a.disabled {
            flex-direction: row;

            gap: .5rem;

            align-items: center;

            color: v.$blackish-40;

            pointer-events: none;

            .small-text {
                padding: f.px2rem(2) f.px2rem(6);

                background-color: v.$blackish-10;
                border: 1px solid v.$blackish-20;
                border-radius: v.$border-radius-sm;
            }
        }
    }

    &.--above-895 {
        @include m.layout-padding;

        overflow: hidden;

        .block-wrapper {
            height: max-content;

            @include m.screen(sm) {
                display: flex !important;

                justify-content: space-between;

                width: 100%;
                max-width: f.px2rem(1200);
                margin: 0 auto;
            }
        }

        [class*="block-"] {
            display: flex;

            gap: f.px2rem(24);

            align-items: center;

            ul {
                display: flex;

                gap: f.px2rem(2);

                align-items: flex-start;
            }
        }

        .block-1 {
            img {
                align-self: center;
            }
        }

        .block-2,
        .block-login {
            gap: f.px2rem(2);
        }

        .block-2-wrapper {
            display: flex;

            align-items: baseline;
        }

        .divider {
            width: 1px;
            height: f.px2rem(20);
        }

        .login {
            display: flex;

            align-items: center;
        }


        .lang-selector {
            position: relative;

            display: flex;

            gap: f.px2rem(10);

            align-items: center;
            justify-content: space-between;

            font-size: 14px;
        }

        .menu-item {
            .item-with-description {
                display: flex;

                flex-direction: column;

                gap: .25rem;

                padding: f.px2rem(14) f.px2rem(16) f.px2rem(15);

                &:has(.small-text) {
                    padding: f.px2rem(12) f.px2rem(16) f.px2rem(10) f.px2rem(16);
                }

                &:has(.disabled) {
                    cursor: not-allowed;
                }

                @include m.screen(sm) {
                    width: max-content;
                }

                .button-menu-header {
                    display: flex;

                    flex-direction: column;

                    gap: f.px2rem(2);

                    &.disabled {
                        flex-direction: row;

                        gap: .5rem;

                        align-items: center;

                        pointer-events: none;

                        .small-text {
                            padding: f.px2rem(2) f.px2rem(6);

                            background-color: v.$blackish-10;
                            border: 1px solid v.$blackish-20;
                            border-radius: v.$border-radius-sm;
                        }
                    }
                }
            }
        }

        .menu-btn {
            padding: f.px2rem(13) f.px2rem(16);

            text-wrap: nowrap;

            border-radius: 999px;

            transition: v.$transition;
        }

        .nav-desktop-sublist {
            position: absolute;

            display: flex;

            flex-direction: column !important;

            padding-top: f.px2rem(38);

            pointer-events: none;

            opacity: 0;

            &:has(.nav-link-title) {
                left: 15%;
            }

            &.--full-width {
                left: 0;

                width: 100%;

                .grid-container {
                    width: 100%;
                    max-width: 75rem;
                    padding-right: 150px;
                    padding-left: 150px;
                    margin: 0 auto;
                }

                .nav-link-title {
                    display: inline-flex;

                    gap: .5rem;

                    align-items: center;

                    margin-bottom: 1rem;
                    margin-left: 1rem;

                    font-size: 1.125rem;
                }

                .inner-grid-container {
                    display: grid;

                    grid-template-rows: repeat(4, minmax(0, auto));
                    grid-template-columns: repeat(4, 1fr);

                    column-gap: 1rem;
                }
            }
        }
    }

    .item-with-description {
        padding-top: f.px2rem(24);

        border-radius: 999px !important;
    }

    .block-1 {
        .item-with-description {
            border-radius: v.$border-radius !important;
        }
    }

    .--highlighted {
        text-wrap: nowrap;
    }

    .chevron-black {
        position: absolute;
        bottom: -55px;
        left: 50%;

        display: block;

        cursor: pointer;

        transform: translateX(-50%);
        animation: bounce 2s infinite;

        @include m.screen("md") {
            display: none;
        }
    }
}

.header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;

    width: 100%;
    height: 100%;

    visibility: hidden;

    opacity: 0;

    transition: all .2s ease-in-out;

    &.--active {
        visibility: visible;

        background-color: v.$blackish-20;
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(4px);
    }
}