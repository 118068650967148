@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.testimonial {
    &.--flip-image {
        @include m.screen(sm) {
            .img-el {
                grid-column: 7/12;
            }

            .content-el {
                grid-column: 2/7;
            }
        }
    }

    .img-el,
    .content-el {
        grid-column: 1/-1;
    }

    .img-el {
        position: relative;

        margin-top: 1.5rem;
        overflow: hidden;

        border-radius: v.$border-radius;


        &::after {
            position:absolute;
            top:0;
            right: 0;
            bottom: 0;
            z-index: 1;

            width: 100%;
            height: 100%;

            content: '';
            
            background: linear-gradient(to bottom, rgba(0,0,0,0%) 50%, rgba(0,0,0, 16%) 75%,rgba(0,0,0, 48%) 100%);
        }

        @include m.screen(sm) {
            grid-column: 2/7;
            
            margin-top: 0;
        }
    }

    .img-content {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        padding: f.px2rem(20);

        div {
            color: v.$white !important;
        }

        @include m.screen(sm) {
            padding: f.px2rem(32);
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    .content-el {
        grid-row: 1;

        @include m.screen(sm) {
            grid-column: 7/12;

            align-self: center;
        }


        .paragraph {
            margin-bottom: 1.5rem;
        }
    }

    svg {
        margin-bottom: 1rem;

        @include m.screen(sm) {
            margin-bottom: 1.5rem;
        }
    }

    // Animations: set initial state
    [data-stagger] {
        opacity: 0;
    }
}
