/* stylelint-disable rule-empty-line-before */
/* stylelint-disable no-descending-specificity */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.card {
    position: relative;

    width: 100%;
    height: 100%;
    overflow: hidden;

    border-radius: v.$border-radius;

    @include m.backdrop-blur;

    // transition: v.$transition;

    &>img,
    &>video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content-el {
        position: relative;

        display: flex;

        flex-direction: column;

        height: 100%;
        padding: f.px2rem(20);

        @include m.screen(sm) {
            padding: 2rem;
        }
    }

    .bg-el {
        position: absolute;
        top: 0;
        left: 0;

        border-radius: v.$border-radius;
    }

    .icon {
        width: f.px2rem(40);

        // margin-bottom: f.px2rem(32);
    }

    .icon-wrapper {
        margin-bottom: f.px2rem(32);
    }

    .title-4,
    .title-5 {
        margin-bottom: f.px2rem(10);

        @include m.screen(sm) {
            margin-bottom: 1rem;
        }
    }

    .chevron {
        @include m.flex-center;
        @include m.backdrop-blur;

        width: 40px;
        height: 40px;
        padding: 0 6px;
        margin-left: auto;

        border-radius: 999px;

        svg {
            margin-left: 1px;
        }
    }

    .tag {
        @include m.tag-styles;

        // pisa line-height del small text
        line-height: 1;
    }

    .flex-container {
        display: flex;

        align-items: center;
        align-self: end;
        justify-content: space-between;

        width: 100%;
        padding-top: 1rem;
        margin-top: auto;
        margin-bottom: 0;

        @include m.screen(sm) {
            padding-top: 3rem;
        }
    }
    
    &.card-simple {
        &.--card-height-sm {
            .flex-container {
                padding-top: 0;
            }
        }
        .icon-wrapper {
            margin-bottom: 1rem;
    
            @include m.screen(sm) {
                margin-bottom: 2rem;
            }
        }
    }
}

// .card-simple {
//     .paragraph {
//         margin-bottom: 1rem;

//         @include m.screen(sm) {
//             margin-bottom: f.px2rem(115);
//         }
//     }
// }

.card-list-item {
    display: flex;

    gap: 1rem;

    padding: 1rem;

    border-radius: v.$border-radius;
}

.card-with-list-of-features {
    .list-items-container {
        display: flex;

        flex-direction: column;

        gap: 0.25rem;

        align-items: flex-start;
        justify-content: flex-start;

        margin-top: 4rem;

        img {
            width: f.px2rem(20);
            height: f.px2rem(20);
        }

        .card-list-item {
            width: 100%;
        }

    }

    .flex-container {
        margin-top: 2rem;
    }
}

.card-marquee {
    // display: flex;

    // align-items: center;
    // justify-content: center;

    // height: 100%;
    // padding: 2rem;

    // border: 1px v.$blackish-10 solid;
    // border-radius: v.$border-radius;

    img {
        display: block;

        max-width: 100%;
        height: auto;
    }

    // @include m.screen(sm) {
    //     width: 400px;
    // }
}

.card-news-small {
    .img-el {
        display: block;
    }

    .content-el {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.card-news-small,
.card-news-big {
    .content-el {
        padding: 1.5rem;
    }

    max-height: 390px;

    @include m.screen(sm) {
        max-height: none;
    }
}

.card-job-listing {
    position: relative;

    padding: 1rem;

    @include m.screen(sm) {
        padding: f.px2rem(24);
    }

    .title-5 {
        margin-bottom: 0.5rem;
    }

    .specs {
        display: flex;

        flex-direction: column;

        width: 85%;
    }

    .spec {
        display: flex;

        gap: .5rem;

        align-items: flex-start;
        justify-content: flex-start;

        .icon {
            width: 16px;
            height: 16px;

            // .card override
            margin-top: 0.25rem;
            margin-bottom: 0;

            svg {
                path {
                    stroke-width: 1px;
                }
            }
        }

        svg {
            width: 1rem;
        }
    }

    a {
        position: static;
    }

    .chevron {
        position: absolute;
        top: 50%;
        right: f.px2rem(24);

        transform: translate(0, -50%);
    }
}

.card-highlight-number {
    .title-1 {
        margin-bottom: .25rem;

        @include m.screen(sm) {
            margin-bottom: .5rem;
        }
    }

    &__title {
        margin-bottom: 2rem;
    }

    .flex-container {
        padding-top: 1rem;
        margin-top: auto;
        margin-bottom: 0;

        @include m.screen(sm) {
            padding-top: 2rem;
        }

        &:not(:has(.tag)) {
            justify-content: flex-end;
        }
    }
}

.card-with-block-image {
    position: relative;

    display: flex;

    flex-direction: column;

    // may be i need it later
    // height: max-content;

    .content-el {
        position: static;

        height: auto;
        padding-bottom: 3rem;
    }

    .title-tag {
        margin-bottom: .25rem;
    }

    .card-with-block-image__title {
        margin-bottom: 1rem;
    }

    .media-el {
        display: flex;

        height: 100%;

        img,
        video {
            width: 100%;

            // height: 100%;

            object-fit: cover;

            margin-top: auto;
        }
    }

    .flex-container {
        position: absolute;
        bottom: f.px2rem(32);

        width: 100%;
        padding: 0 f.px2rem(32);
        margin-top: f.px2rem(48);
    }

    &.--column-reverse {
        flex-direction: column-reverse;

        .content-el {
            padding-bottom: 2rem;
        }

        .flex-container {
            position: relative;
            bottom: 0;

            padding: 0;
        }
    }
}

.card-staff {
    position: relative;

    .gradient {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        color: v.$white;

        background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 50%, rgba(0, 0, 0, 16%) 75%, rgba(0, 0, 0, 48%) 100%);
    }

    .content-wrapper {
        position: absolute;
        bottom: 0;

        padding: f.px2rem(20);

        @include m.screen(sm) {
            padding: 2rem;
        }
    }

    .title-5 {
        // .card override
        margin-bottom: 0;
    }
}

.card-new {
    position: relative;

    // Animations: set initial state
    opacity: 0;

    .image-wrapper {
        position: relative;

        aspect-ratio: 8 / 5;
        overflow: hidden;

        border-radius: v.$border-radius;

        &__img {
            width: 100%;
            height: 100%;

            background: no-repeat center center;
            background-size: cover;

            transition: v.$transition;
        }

        .tag {
            position: absolute;
            top: 1rem;
            right: 1rem;

            @include m.tag-styles;
        }
    }

    .content-wrapper {
        height: max-content;
    }


    .title {
        margin-top: f.px2rem(24);
        margin-bottom: .25rem;

        @include m.screen(sm) {
            margin-bottom: .5rem;
        }
    }

    .title:is(.title-5) {
        margin-top: 1rem;
        margin-bottom: 0.25rem;
    }

    .author-date-wrapper {
        display: flex;

        gap: .25rem;

        align-items: center;
    }

    &:hover {
        .image-wrapper__img {
            scale: 1.1
        }
    }
}

.card-inversiones {
    @include m.backdrop-blur;

    .content-el {
        position: absolute;
        top: 0;
        left: 0;

        justify-content: space-between;

        padding: f.px2rem(20);

        .title {
            max-width: 320px;
        }

        .btn {
            width: fit-content;
        }

        @include m.screen(sm) {
            padding: 2rem;
        }
    }
}

.card-emisiones {
    height: auto;

    backdrop-filter: none;

    .card-header,
    .content-block {
        padding: 1rem 1.5rem;
    }

    .card-header {
        display: flex;

        justify-content: space-between;

        .block-1 {
            align-items: center;
        }

        .title {
            display: flex;

            flex-direction: row;

            gap: .5rem;

            align-items: center;
            justify-content: center;

            font-weight: 700;
        }

        .item-ticker {
            display: inline-flex;

            flex-direction: row;

            gap: .5rem;

            align-items: center;
            justify-content: center;
        }

        .coin {
            width: max-content !important;
        }
    }

    .content-block {
        display: flex;

        justify-content: space-between;
    }

    .block-data {
        display: flex;

        gap: 2.5rem;
    }

    .dates-wrapper,
    .content-wrapper {
        display: flex;

        flex-direction: column;

        gap: .25rem;
    }

    .block-1,
    .block-2 {
        display: flex;

        gap: .5rem;
    }

    &-list {
        display: flex;

        flex-direction: column;

        gap: 1rem;
    }

    .block-pills {
        max-width: max-content;

        &-wrapper {
            display: flex;

            flex-direction: column;

            align-items: flex-end;
            justify-content: space-between;

            width: max-content;
            height: 100%;
        }

        &-container {
            display: flex;

            gap: .25rem;
        }
    }

    .item-max-capital-agente-colocador {
        display: flex;

        gap: .25rem;

        align-items: center
    }

    .pill {
        display: inline-flex;

        gap: .25rem;

        align-items: center;

        border-radius: 999px !important;

        &.--pill-green {
            background: #DAF7D9;
        }

        &.--pill-sustainability {
            color: #12A150;

            background: #D1F4E0;
        }

        &.--pill-download {
            background: v.$blackish;

            .card-icon {
                height: f.px2rem(14);
            }
        }
    }
}

.card-emisiones-item {
    display: flex;

    gap: .25rem;

    align-items: center;

    .content {
        display: flex;

        justify-content: space-between;

        .block-data {
            display: flex;

            gap: 2.5rem;

            .content-wrapper {
                display: flex;

                flex-direction: column;

                gap: .25rem;
            }
        }
    }
}

.card-emisiones-mobile {
    height: auto;

    .card-header {
        padding: 1rem;
    }

    .card-header {
        display: grid;

        grid-template-rows: 1fr auto;
        grid-template-columns: auto auto;

        column-gap: .5rem;

        align-content: start;

        width: 100%;

        .title {
            margin-bottom: 0;

            font-weight: 700;
        }

        .item-ticker {
            display: inline-flex;

            flex-direction: row;

            gap: .5rem;

            align-items: center;
            justify-content: center;
        }

        .block-1,
        .block-2 {
            grid-column: 1/2;
        }

        .block-1 {
            display: flex;

            flex-wrap: wrap;

            column-gap: .5rem;

            .company {
                flex: 0 0 100%;
            }

            .pill {
                margin-top: f.px2rem(16);

                &.coin {
                    width: 22px;
                    width: max-content;
                    height: 22px;
                    margin-top: 0;
                }
            }
        }

        .chevron {
            grid-row: 1/2;
            grid-column: 2/3;

            justify-self: end;

            cursor: pointer;

            transform: rotate(90deg);
        }

        .block-2 {
            display: flex;

            align-items: center;
        }
    }

    .dates-wrapper {
        display: grid;

        grid-template-columns: 1fr 1fr;

        gap: .25rem;

        padding: 1rem;

        border-bottom: 1px red solid;
    }

    .block-pills-container {
        display: flex;

        flex-direction: column;

        gap: .25rem;

        padding: 1rem;
    }

    .content-wrapper {
        padding: 1rem;
        padding-bottom: 0;
    }

    .pill {
        display: flex;

        gap: .25rem;

        align-items: center;
        justify-content: center;

        width: max-content;

        border-radius: 999px !important;

        &.--pill-green {
            background: #DAF7D9;
        }

        &.--pill-sustainability {
            color: #12A150;

            background: #D1F4E0;
        }

        &.--pill-download {

            .card-icon {
                height: f.px2rem(14);
            }
        }
    }

    .description {
        display: flex;

        flex-direction: column;

        align-items: center;


        padding: 1rem;

        .item-max-capital-agente-colocador {
            display: flex;

            gap: .25rem;

            align-items: center
        }

        .download {
            width: f.px2rem(240);
            margin-bottom: .5rem;
        }
    }
}

.card-emisiones,
.card-emisiones-mobile {
    .pill {
        text-wrap: nowrap;
    }
}

.card-product {
    .chevron {
        background-color: v.$blackish-10;

        @include m.backdrop-blur;
    }
}

.card-research {
    // Animations: Initial state
    opacity: 0;
    /* stylelint-disable-next-line scss/comment-no-empty */

    .card-simple__tag {
        margin-bottom: 4px;
    }

    .title-5 {
        font-weight: 500;
    }
}