@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.pagination {
    display: flex;

    gap: 1rem;

    width: max-content;
    margin: 4rem auto 0;

    .page-item {
        .page-link {
            padding: f.px2rem(10) f.px2rem(16);

            cursor: pointer;

            background-color: v.$white;
            border-radius: .5rem;
        }

        &.active {
            .page-link {
                color: v.$white;

                background-color: v.$blackish;
            }
        }

        &.disabled {
            .page-link {
                pointer-events: none;
                cursor: pointer;

                background-color: v.$blackish-10;
            }
        }

        display: none;

        @include m.screen(sm) {
            display: block;
        }

        &.previous,
        &.active,
        &.next {
            display: block;
        }
    }

    .page-break {
        display: none;

        @include m.screen(sm) {
            display: block;
        }
    }
}

.pagination-component {
    display: flex;

    grid-column: 1 / -1;

    gap: 1rem;

    justify-content: center;

    margin-top: 4rem;

    // affect a without <a> tag inside and a
    li:has(:not(a)), a {
        padding: 0.625rem 1.25rem;

        border: 1px solid v.$blackish-10;
        border-radius: .5rem;

        transition: v.$transition;

        &:hover {
            border: 1px solid v.$blackish;
        }

        &.active {
            color: v.$white;            

            background-color: v.$blackish;
        }
    }

    .paragraph {
        a {
            text-decoration: none;
        }
    }
} 