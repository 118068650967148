@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.simple-hero {
    .content {
        grid-column: 1/-1;
    }

    .tag {
        display: flex;

        gap: .5rem;

        width: max-content;
        margin-bottom: f.px2rem(40);
    }

    .title-hero {
        margin-bottom: f.px2rem(10);

        @include m.screen(sm) {
            margin-bottom: f.px2rem(16)
        }
    }

    .paragraph-hero {
        grid-column: 1/6;
    }

    a.btn {
        margin-top: f.px2rem(40);

        @include m.screen(sm) {
            margin-top: f.px2rem(64)
        }
    }

    // CONTENT POSITION MODIFIERS
    &.--content-left,
    &.--content-center,
    &.--content-right {
        .content {
            grid-column: 1/-1;
        }
    }

    &.--content-left {
        .content {
            @include m.screen(sm) {
                grid-column: 1/-1;
            }
        }

        .text-content {
            @include m.grid;

            &>* {
                grid-column: 1/-1;
            }

            .title-hero {
                grid-column: 1/11;
            }

            .paragraph-hero {
                grid-column: 1/7;
            }
        }
    }

    &.--content-center {

        /* stylelint-disable-next-line no-descending-specificity */
        .paragraph-hero {
            margin-bottom: f.px2rem(40);
        }

        text-align: center;

        @include m.screen(sm) {
            .text-content {
                @include m.grid;

                &>* {
                    grid-column: 1/-1;
                }

                .tag {
                    margin: 0 auto;
                }

                .paragraph-hero {
                    grid-column: 4/10;
                }

                .title-hero {
                    grid-column: 2/12;
                }
            }
        }
    }

    &.--content-right {
        .content {
            @include m.screen(sm) {
                grid-column: 7/-1;
            }
        }

        .text-content {
            @include m.screen(sm) {
                display: grid;

                grid-template-columns: repeat(6, minmax(auto, 1fr));

                column-gap: f.px2rem(40);

                .tag,
                .title-hero,
                .paragraph-hero {
                    grid-column: 1/-1;
                }
            }
        }
    }

    [data-stagger] {
        opacity: 0;

        transform: translateY(20px);
    }
}