@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.news {
    grid-column: 1 / -1;

    .card-news-container {
        grid-column: span 4;

        height: max-content;
        
        // Animations: set initial state
        opacity: 0;

        // transition: v.$transition;
    }

    .card-news-container:nth-of-type(1) {
        grid-column: span 6;

        height: auto;
    }

    .card-news-container:nth-of-type(2),
    .card-news-container:nth-of-type(3) {
        grid-column: span 3;

        padding-bottom: 10rem;
    }
}