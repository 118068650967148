/* stylelint-disable scss/double-slash-comment-empty-line-before */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

// Marquee done with these tutorials:
// https://ryanmulligan.dev/blog/css-marquee/
// https://codepen.io/hexagoncircle/pen/eYMrGwW

/* Marquee styles */
.marquee {
    --gap: 1rem;

    position: relative;

    display: flex;

    gap: var(--gap);

    overflow: hidden;

    user-select: none;

    @include m.side-gradients-marquee(var(--bg-color));

    &.--cards-fluid-height {

        // uncomment this to let the height card be set according to the childrens
        .card {
            height: auto !important;
        }
    }
}

.marquee__content {
    display: flex;

    flex-shrink: 0;

    gap: var(--gap);

    align-items: flex-start;
    justify-content: flex-start;
    justify-content: space-around;

    // min-width: 100%;

    // /* stylelint-disable-next-line no-descending-specificity */
    // &>.card-marquee {
    //     width: f.px2rem(200);
    // }
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}

/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
    .marquee__content {
        animation-play-state: paused !important;
    }
}

.marquee-splide {
    margin-bottom: 4rem;
    margin-top: 4rem;

    @include m.side-gradients-marquee(var(--bg-color));
    
    li {
        &.splide__slide {
            width: max-content !important;
        }
    }
}

/* Enable animation */
.enable-animation .marquee__content {
    animation: scroll var(--marquee-mob-duration) linear infinite;

    @include m.screen(sm) {
        animation: scroll var(--marquee-desktop-duration) linear infinite;
    }
}

/* Reverse animation */
.marquee--reverse .marquee__content {
    animation-direction: reverse;
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
    animation-play-state: paused;
}

/* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
.marquee--fit-content {
    max-width: fit-content;
}

/* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
.marquee--pos-absolute .marquee__content:last-child {
    position: absolute;
    top: 0;
    left: 0;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation .marquee--pos-absolute .marquee__content:last-child {
    animation-name: scroll-abs;
}

@keyframes scroll-abs {
    from {
        transform: translateX(calc(100% + var(--gap)));
    }

    to {
        transform: translateX(0);
    }
}
