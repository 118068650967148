@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.modal-react {
    position: relative;
    z-index: 10;

    width: 100%;

    @include m.screen(sm) {
        max-width: f.px2rem(640);

    }

    .title-5:not(.filter-category-title) {
        margin-bottom: f.px2rem(64);
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgb(0 0 0 / 50%);
    }

    .content-el {
        position: relative;
        z-index: 99;

        display: flex;

        // align-items: center;

        max-height: f.px2rem(640);
        padding: 1.5rem;

        background-color: v.$white;
        border-top-left-radius: v.$border-radius;
        border-top-right-radius: v.$border-radius;

        @include m.screen(sm) {
            border-radius: v.$border-radius;
        }
    }

    .content-panel {
        width: 100%;
    }

    .chevron {
        position: absolute;
        top: 1rem;
        right: 1rem;

        cursor: pointer;
    }

    .btns-2-cols {
        display: flex;

        gap: .25rem;

        width: 100%;
        padding: 1rem;
        margin-top: f.px2rem(32);

        &>* {
            flex: 1;
        }
    }

    .filters {
        flex-direction: column;

        justify-content: start;

        max-height: 24rem;
        overflow-y: auto;

        scrollbar-width: thin;
        scrollbar-color: v.$blackish v.$white-80;
    }

    .filterGroup {
        display: flex;

        flex-direction: column;

        gap: 1rem;

        margin-bottom: 2rem;
    }

    .filter-pill {
        .year {
            display: none;
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.duration-300 {
    transition: all 300ms ease-in-out;
}

.duration-300 {
    transition: all 300ms ease-in-out;
}

.opacity-100 {
    opacity: 1;
}

.ease-out {
    transition: all 300ms ease-out;
}

.ease-in {
    transition: all 300ms ease-in;
}

.scale-100 {
    transform: scale(1);
}

.scale-95 {
    transform: scale(0.95);
}

// Need this selector to target a orphan div used with @headlessui/react
div[data-headlessui-portal] {
    &>div {
        display: flex;

        align-items: flex-end;
        justify-content: center;

        height: 100dvh;

        @include m.screen(sm) {
            align-items: center;
            justify-content: center;

            padding: f.px2rem(40);
        }
    }
}