@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.toast {
    padding: f.px2rem(8) 0;

    color: v.$white-70;
    text-align: center;

    button,
    a {
        color: v.$white;
        text-decoration: underline;

        background-color: transparent;
    }
}