@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

:root {
    --swiper-theme-color: #{v.$blackish} !important;
}

* {
    text-wrap: balance;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: Inter, sans-serif !important;
}

a {
    color: inherit;
    text-decoration: none;
}