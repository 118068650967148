@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.grid-products {
    .card-product {
        grid-column: span 4;

        // Animations: set initial state
        opacity: 0;        
    }
}
