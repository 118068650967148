@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.accordion {
    &__container {
        &__content {
            display: flex;

            flex-direction: column;

            grid-column: 1/-1;

            gap: 1rem;

            padding-left: 0;

            list-style: none;

            @include m.screen("md") {
                grid-column: 2/-2;
            }

            @include m.screen("lg") {
                grid-column: 3/-3;
            }

        }
    }

    &__visible-part {
        // Animations: set initial state
        opacity: 0;
        transform: translateY(25px);
        
        /* stylelint-disable-next-line order/properties-order */
        padding: f.px2rem(16);

        cursor: pointer;

        border: 1px solid transparent;
        border-radius: v.$border-radius;

        transition: border .2s ease-in-out;

        &:hover {
            border: 1px solid transparent;
        }

        &.--active {
            border: 1px solid transparent;
        }

        @include m.screen("sm") {
            padding: f.px2rem(18);
        }

        .accordion {
            display: flex;

            align-items: center;
            justify-content: space-between;

            svg {
                margin-top: 2px;
            }
        }

        .panel {
            max-height: 0;
            padding-right: f.px2rem(40);
            overflow: hidden;

            border: none;

            transition: max-height 0.3s ease-in-out;

            .paragraph {
                margin-top: f.px2rem(32);
            }
        }
    }
}

.footer {
    .accordion {
        &__visible-part {
            // Aniamtions: remove initial state from footer accordion
            opacity: 1;
            transform: translateY(0);
        }
    }
}