/* stylelint-disable rule-empty-line-before */
/* stylelint-disable no-descending-specificity */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.ant-input-search,
.ant-input {
    &.--theme-light {
        color: v.$blackish-60;

        background-color: v.$white;
        border-color: v.$blackish-10;

        // override antd's default focus color
        &:focus,
        &:focus-within {
            background-color: v.$white;
        }

        @include m.screen(sm) {
            &:hover {
                background-color: v.$white;
            }
        }

        input {
            &::placeholder {
                color: v.$blackish-60 !important;
            }

            &:hover,
            &:focus,
            &:focus-within {
                border-color: v.$blackish-60;
            }
        }

        .ant-input-group-addon {
            .ant-btn {
                color: v.$blackish-60 !important;

                background-color: v.$white !important;
                border-color: v.$blackish-10 !important;

                &:hover {
                    border: 1px solid v.$blackish-60 !important;
                }
            }

        }
    }

    &.--theme-dark {
        input {
            color: v.$white;

            background-color: v.$blackish-80;
            border-color: v.$white-20;

            // override antd's default focus color
            &:focus,
            &:focus-within {
                background-color: v.$blackish-80;
            }

            @include m.screen(sm) {

                &:hover,
                &:focus {
                    background-color: v.$blackish-80;
                    border-color: v.$white;
                }
            }

            &::placeholder {
                color: v.$white !important;
            }
        }

        .ant-input-group-addon {
            .ant-btn {
                color: v.$white !important;

                background-color: v.$blackish-80 !important;
                border-color: v.$white-20 !important;
            }
        }
    }
}

.ant-tooltip-inner {
    color: v.$blackish-60 !important;
}

.ant-select-selector {
    width: 100%;
}

.ant-drawer {
    &-header {
        svg {
            width: f.px2rem(40);
            height: f.px2rem(40);
        }
    }

    .ant-select {
        width: 100%;
    }
}

.ant-tabs {
    font-family: Inter, sans-serif !important;
}