/* stylelint-disable rule-empty-line-before */
/* stylelint-disable keyframes-name-pattern */
@use "../abstracts/mixins" as m;
@use "../abstracts/variables" as v;

@keyframes skeletonAnimation {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.ant-skeleton {
    .darkMode.darkMode {
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 6%) 25%, rgba(255, 255, 255, 15%) 37%, rgba(255, 255, 255, 6%) 63%);
        background-size: 400% 100% !important;

        animation-name: skeletonAnimation !important;
        animation-duration: 1.4s !important;
        animation-timing-function: ease !important;
        animation-iteration-count: infinite !important;
    }
}

.home {
    &__marquee {
        width: 270px !important;
        height: 73px !important;

        @include m.screen(md) {
            width: 123px;
        }
    }

    &__product-slide {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: 1;

        border-radius: v.$border-radius !important;
    }

    &__price-ticker {
        width: 100% !important;
        height: 100px !important;

        border-radius: v.$border-radius !important;
    }

    &__panels-slider {
        width: 70% !important;
        height: 50% !important;
        min-height: 200px;

        border-radius: v.$border-radius !important;

        span {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.instrument {
    &__tabs {
        &__search-bar {
            width: 100% !important;
            height: 5rem !important;
            margin-bottom: 1.5rem;
        }

        &__table-column {
            gap: 1.5rem;

            width: 100% !important;

            .ant-skeleton-button {
                width: 100% !important;
                height: 2rem !important;
                margin-bottom: 0 !important;
            }
        }
    }
}

.skeleton-product-detail {

    .ant-skeleton-input,
    .ant-skeleton-title {
        background-image: linear-gradient(90deg, rgba(128, 128, 128, 15%) 25%, rgba(128, 128, 128, 30%) 37%, rgba(128, 128, 128, 15%) 63%) !important;
    }

    .ant-skeleton-paragraph li {
        display: none;
    }
}

.skeleton-line-chart {

    .ant-skeleton,
    .ant-skeleton-image {
        width: 100% !important;
        height: 18.75rem !important;
    }

    // .ant-skeleton-image {
    //     height: 18.75rem !important;
    // }
}