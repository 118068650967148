@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.only-text {
    .content {
        grid-column: 1/-1;
    }

    // .text-content {
    //     margin-bottom: f.px2rem(40);

    //     @include m.screen(sm) {
    //         margin-bottom: f.px2rem(64)
    //     }
    // }

    .tag {
        display: flex;

        gap: .5rem;

        width: max-content;
        margin-bottom: f.px2rem(40);
    }

    .paragraph {
        margin-top: f.px2rem(10);

        @include m.screen(sm) {
            margin-top: f.px2rem(16)
        }
    }

    .btn {
        margin-top: f.px2rem(40);

        @include m.screen(sm) {
            margin-top: f.px2rem(64)
        }
    }

    // CONTENT POSITION MODIFIERS
    &.--content-left,
    &.--content-center,
    &.--content-right {
        .content {
            grid-column: 1/-1;
        }
    }

    &.--content-left {
        .content {
            @include m.screen(sm) {
                grid-column: 1/-1;
            }
        }

        .text-content {
            @include m.grid;

            &>* {
                grid-column: 1/-1;
            }

            .title-1 {
                grid-column: 1/11;
            }

            .paragraph {
                grid-column: 1/7;
            }
        }
    }

    &.--content-center {
        .paragraph-hero {
            margin-bottom: f.px2rem(40);
        }

        text-align: center;

        @include m.screen(sm) {
            .text-content {
                @include m.grid;

                &>* {
                    grid-column: 1/-1;
                }

                .tag {
                    margin: 0 auto;
                }

                .paragraph-hero {
                    grid-column: 1/-1;
                }

                .title-1 {
                    grid-column: 2/12;
                }

                .paragraph {
                    grid-column: 4/10;
                }
            }
        }
    }

    &.--content-right {
        .content {
            @include m.screen(sm) {
                grid-column: 7/-1;
            }
        }

        .text-content {
            @include m.screen(sm) {
                display: grid;

                grid-template-columns: repeat(6, minmax(auto, 1fr));

                column-gap: f.px2rem(40);

                .tag,
                .title-1,
                .paragraph {
                    grid-column: 1/-1;
                }
            }
        }
    }
}