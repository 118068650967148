// --------------------------------------------------
// Breakpoints
// Based on Tailwind CSS
// https://tailwindcss.com/docs/breakpoints
// --------------------------------------------------

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;
$xxxl: 1920px;

// --------------------------------------------------
// Colors
// --------------------------------------------------

$blackish: #111111;
$white: #ffffff;
$blackish-5: rgba(17, 17, 17, 5%);
$blackish-10: rgba(17, 17, 17, 10%);
$blackish-20: rgba(17, 17, 17, 20%);
$blackish-30: rgba(17, 17, 17, 30%);
$blackish-40: rgba(17, 17, 17, 40%);
$blackish-50: rgba(17, 17, 17, 50%);
$blackish-60: rgba(17, 17, 17, 60%);
$blackish-70: rgba(17, 17, 17, 70%);
$blackish-80: rgba(17, 17, 17, 80%);
$blackish-90: rgba(17, 17, 17, 90%);
$white-10: rgba(255, 255, 255, 10%);
$white-20: rgba(255, 255, 255, 20%);
$white-30: rgba(255, 255, 255, 30%);
$white-40: rgba(255, 255, 255, 40%);
$white-50: rgba(255, 255, 255, 50%);
$white-60: rgba(255, 255, 255, 60%);
$white-70: rgba(255, 255, 255, 70%);
$white-80: rgba(255, 255, 255, 80%);
$white-90: rgba(255, 255, 255, 90%);
$green: #269532;
$red: #F5222D;

// --------------------------------------------------
// Border radius
// --------------------------------------------------

$border-radius: 0.75rem; // 12px
$border-radius-sm: 0.5rem; // 8px

// --------------------------------------------------
// Width
// --------------------------------------------------
$max-width-wrapper: 1440px;
$section-padding-sm: 4rem;
$section-padding-md: 6rem;
$section-padding-lg: 8rem;
$hero-desktop-height: 53rem;

// --------------------------------------------------
// Animations
// --------------------------------------------------
$timming: 0.2s;
$transition: all $timming ease-in-out;