/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

.--theme-dark {
    .cot-base-card {
        background-color: v.$blackish;
        border: 1px solid v.$white-10;

        .title {
            color: v.$white;
        }

        .value {
            color: v.$white-80;
        }

        .label {
            color: v.$white-60;
        }
    }

    &.badge-tag {
        &.--open-market {
            background: #269532;
            border: 1px #124D19 solid;

            .tag {
                color: v.$white-50;
            }
        }

        &.--close-market {
            background: #CF2B2B;
            border: 1px #9C2020 solid;

            .tag {
                color: v.$white-50;
            }
        }
    }
}

.--theme-light {
    .cot-base-card {
        background-color: v.$white;
        border: 1px solid v.$blackish-10;

        .title {
            color: v.$blackish;
        }

        .value {
            color: v.$blackish-80;
        }

        .label {
            color: v.$blackish-60;
        }
    }

    &.badge-tag {
        &.--open-market {
            background: #F6FFED;
            border: 1px #B7EB8F solid;

            .tag {
                color: #269532;
            }
        }

        &.--close-market {
            background: #FFF0F0;
            border: 1px #EDABAB solid;

            .tag {
                color: #CF2B2B;
            }
        }
    }
}