/* stylelint-disable rule-empty-line-before */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.tabs:not(.--react) {
    .article-block {
        position: relative;

        overflow: hidden;

        // background: #ffffff;
        // border-bottom: 1px solid rgba(27, 177, 165, 30%);
        // border-radius: 24px;
        // box-shadow: 0 10px 12px -12px rgba(0, 0, 0, 40%) inset;
    }

    .article {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        // padding: 20px;

        line-height: 1.5;
    }
}

.tabs-block-container {
    position: relative;

    display: flex;

    align-items: center;

    .line {
        width: 100%;
        height: f.px2rem(1);
    }

    .slider {
        position: absolute;
        top: 0;
        top: 50%;
        left: 0;

        height: calc(100% - f.px2rem(4));

        background: v.$blackish;
        border-radius: 100px;

        transform: translateY(-50%);
    }
}

.tabs-block {
    position: relative;
    z-index: 10;

    display: flex;

    flex: 1 0 auto;
    flex-direction: row;

    width: max-content;
    padding: f.px2rem(2);
    margin: 0 auto;

    border: 1px solid rgba(0, 0, 0, 10%);
    border-radius: 100px;


    li {
        position: relative;

        flex: auto;

        padding: f.px2rem(12) f.px2rem(24);
        margin: 0;

        font-size: 0.875rem;
        font-weight: 500;
        text-align: center;
        list-style: none;

        cursor: pointer;

        @include m.screen(sm) {
            font-size: 1rem;
        }
    }
}

.tabs.--react {
    .tab-item {
        transition: v.$transition;
    }

    .--tab-active {
        background-color: v.$white;
        border-radius: 999px;
    }

    .tabs-block {
        z-index: 0;
    }

    .empty-state {
        display: flex;

        flex-direction: column;

        gap: 0.5rem;

        align-items: center;

        margin: 4rem 0;

        >* {
            text-align: center;
        }

        @include m.screen(sm) {
            margin: 6rem 0;
        }
    }
}

.card-emisiones-list.--dotted {
    border: 1px dashed v.$blackish-20;
}