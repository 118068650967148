@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.modal {
    width: 100%;
    max-width: 100%;

    // margin-top: auto;
    // margin-bottom: 0;
    overflow: visible;

    border: 0;
    border-radius: v.$border-radius;
    box-shadow: var(--shadow-2), 0 0 0 100vw rgb(0 0 0 / 80%);

    @include m.screen(md) {
        width: max-content !important;
        margin: auto;
    }

    .icon-close {
        position: absolute;
        top: 1rem;
        right: 1rem;

        color: v.$white;

        cursor: pointer;
    }
}


.modal::backdrop {
    background: rgb(0 0 0 / 80%);
    opacity: 0;
}

.modal[open] {
    position: fixed !important;
    top: 0;
    left: 0;

    animation: fade-in 500ms forwards;
}

.modal[open]::backdrop {
    animation: fade-in 500ms forwards;
}

.modal[closing] {
    inset: 0;

    display: block;

    pointer-events: none;

    animation: fade-out 500ms forwards;
}

.modal[closing]::backdrop {
    animation: fade-out 500ms forwards;
}

// -------------------
// Pop Up Modal Styles
// -------------------
.modal[open] {
    @include m.screen(md) {
        width: 100%;
        max-width: f.px2rem(700);
    }

    @include m.screen(lg) {
        width: 100%;
        max-width: f.px2rem(800);
    }
}

.modal[data-modal="pop-up"][open] {
    display: flex;

    flex-direction: column;

    width: 80%;
    margin: auto;

    @include m.screen(md) {
        flex-direction: row;
    }

    &>* {
        flex: 0 1 50%;
    }


    .image-wrapper {
        border-top-left-radius: v.$border-radius;
        border-top-right-radius: v.$border-radius;

        img {
            width: 100%;
            height: 100%;
            max-height: f.px2rem(300);

            border-radius: v.$border-radius v.$border-radius 0 0;

            object-fit: cover;

            @include m.screen(md) {
                max-height: none;

                border-radius: v.$border-radius 0 0 v.$border-radius;
            }
        }
    }

    .content-wrapper {
        padding: 1rem;

        text-align: center;

        background: v.$white;
        border-radius: 0 0 v.$border-radius v.$border-radius;

        @include m.screen(md) {
            max-height: none;

            border-radius: 0 v.$border-radius v.$border-radius 0;
        }

        @include m.screen(md) {
            padding: f.px2rem(82) f.px2rem(64);
        }

        .tag {
            text-transform: uppercase;
        }

        .tag,
        .title {
            margin-bottom: f.px2rem(10);

            @include m.screen(md) {
                margin-bottom: 1rem;
            }
        }

        .paragraph {
            margin-bottom: 2.5rem;

            @include m.screen(md) {
                margin-bottom: 2;
            }
        }
    }
}

.modal[data-modal="redirect-modal"][open], .modal[data-modal="uruguay-modal"][open] {
    display: flex;

    flex-direction: column;

    @include m.screen(md) {
        flex-direction: row;
    }

    @include m.screen(md) {
        width: 100%;
        max-width: f.px2rem(430);
    }

    // @include m.screen(lg) {
    //     width: 100%;
    //     max-width: f.px2rem(800);
    // }

    .content-wrapper {
        padding: 1rem;

        @include m.screen(md) {
            padding: f.px2rem(24);
        }

        .tag {
            text-transform: uppercase;
        }

        .tag,
        .title {
            margin-bottom: f.px2rem(10);

            @include m.screen(md) {
                margin-bottom: 1rem;
            }
        }

        .paragraph {
            margin-bottom: 2.5rem;

            @include m.screen(md) {
                margin-bottom: 2;
            }
        }

        .btns-2-cols {
            display: flex;

            gap: .5rem;

            &>* {
                width: 100%;
            }

            .btn {
                padding-right: 1rem;
                padding-left: 1rem;

                text-align: center;
            }
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}