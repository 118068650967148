@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.cookies {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 100%;

    background-color: rgba(v.$blackish, .5);

    .content-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;

        max-width: 100%;
        max-height: 999px;
        padding: 1rem;

        background-color: v.$white;

        @include m.screen(sm) {
            right: 2.5rem;
            bottom: 2.5rem;

            max-width: f.px2rem(450);
            padding: 1.5rem;

            border-radius: f.px2rem(12);
        }
    }

    [class^="title-"] {
        margin-bottom: f.px2rem(10);

        @include m.screen(sm) {
            margin-bottom: f.px2rem(16);
        }
    }


    .block {
        .subtitle {
            margin-bottom: .5rem;

            font-weight: 600;

            // theme override
            color: v.$blackish !important;
        }
    }

    .btn {
        padding-right: f.px2rem(20);
        padding-left: f.px2rem(20);
    }

    .btns-2 {
        display: flex;

        gap: .5rem;

        justify-content: space-between;

        margin-top: f.px2rem(26);

        @include m.screen(sm) {
            margin-top: f.px2rem(40);
        }

        .btn {
            width: 100%;
        }
    }

    .config {
        width: 100%;
        margin-top: f.px2rem(10);

        @include m.screen(sm) {
            margin-top: f.px2rem(16);
        }

        .btn {
            width: 100%;
        }
    }

    .preferences {
        position: absolute;
        top: 0;
        left: 0;

        overflow: hidden;

        opacity: 0;

        .block {
            [class^="title-"] {
                margin-bottom: f.px2rem(8);
            }

            margin-bottom: 2rem;
        }

        .text-link {
            display: block;

            margin-top: 1rem;
        }
    }

    .toggle {
        margin-bottom: .5rem;
    }
}