@use "functions" as f;
@use "variables" as v;
@use "mixins" as m;

.--richtext {

    b,
    strong {
        font-weight: 700;
    }

    a {
        font-weight: 700;
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    // p {
    //     margin-bottom: 1.5rem;
    // }

    img {
        display: block;

        max-width: 100%;
        height: auto;
        margin: 2rem 0;
    }

    hr {
        display: block;

        margin: 1.5rem 0;

        border-bottom: 0;

        &~p {
            margin-top: 2rem;
        }
    }

    ul {
        padding-left: 24px;

        list-style: disc;

        // li {
        //     padding-left: 16px;
        // }

        ul {
            list-style: circle;

            li {
                ul {
                    list-style: square;
                }
            }
        }
    }
}

.--text-center {
    text-align: center;
}

.--hidden {
    display: none;
}

.--with-gap {
    gap: 2rem !important;

    @include m.screen("md") {
        gap: 2.5rem !important;
    }
}

.--has-bg-img {
    background-color: transparent !important;
    backdrop-filter: none !important;
}