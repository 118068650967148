@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../base/fonts" as fonts;

.title-hero,
.title-hero * {
    // @include fonts.title-hero;

    @include fonts.title-hero-clamp-cq;
}

.title-great-hero,
.title-great-hero * {
    @include fonts.title-great-hero-clamp;
}

// .title-hero-clamp {
//     @include fonts.title-hero-clamp;
// }

// .title-hero-clamp-cq {
//     @include fonts.title-hero-clamp-cq;
// }

.paragraph-hero,
.paragraph-hero * {
    // @include fonts.paragraph-hero;

    @include fonts.paragraph-hero-clamp-cq;
}

// .paragraph-hero-clamp {
//     @include fonts.paragraph-hero-clamp;
// }

// .paragraph-hero-clamp-cq {
//     @include fonts.paragraph-hero-clamp-cq;
// }

.title-1,
.title-1 * {
    // @include fonts.title-1;

    @include fonts.title-1-clamp-cq;
}

// .title-1-clamp {
//     @include fonts.title-1-clamp;
// }

// .title-1-clamp-cq {
//     @include fonts.title-1-clamp-cq;
// }

.title-2,
.title-2 * {
    // @include fonts.title-2;

    @include fonts.title-2-clamp-cq;
}

// .title-2-clamp {
//     @include fonts.title-2-clamp;
// }

// .title-2-clamp-cq {
//     @include fonts.title-2-clamp-cq;
// }

.title-3,
.title-3 * {
    @include fonts.title-3;

    @include fonts.title-3-clamp-cq;
}

// .title-3-clamp {
//     @include fonts.title-3-clamp;
// }

// .title-3-clamp-cq {
//     @include fonts.title-3-clamp-cq;
// }

.title-4,
.title-4 * {
    // @include fonts.title-4;

    @include fonts.title-4-clamp-cq;
}

// .title-4-clamp {
//     @include fonts.title-4-clamp;
// }

// .title-4-clamp-cq {
//     @include fonts.title-4-clamp-cq;
// }

.title-5,
.title-5 * {
    // @include fonts.title-5;

    @include fonts.title-5-clamp-cq;
}

// .title-5-clamp {
//     @include fonts.title-5-clamp;
// }

// .title-5-clamp-cq {
//     @include fonts.title-5-clamp-cq;
// }

.paragraph {
    // @include fonts.paragraph;

    @include fonts.paragraph-clamp-cq;

    display: flex;

    flex-direction: column;

    gap: 1rem;

    a {
        text-decoration: underline;
    }
}

// .paragraph-clamp {
//     @include fonts.paragraph-clamp;
// }

// .paragraph-clamp-cq {
//     @include fonts.paragraph-clamp-cq;
// }

.tag {
    @include fonts.tag;
}

.small-text {
    @include fonts.small-text;
}

.medium-text {
    @include fonts.medium-text;
}

.button-menu {
    @include fonts.button-menu-font-style;
}

.button-menu-header {
    @include fonts.button-menu-font-style-header;
}

.text-link {
    @include fonts.text-link-style;
}

:where(.title-1, .title-2, .title-3, .title-4, .title-5, .paragraph, .small-text, .tag) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}