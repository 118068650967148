@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.sitemap {
    padding-top: 6rem;

    &_section {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include m.screen("md") {
            margin-top: 5rem;
        }
    }

    .divider {
        height: 1px;
        margin-top: f.px2rem(20);
        margin-bottom: f.px2rem(40);

        background-color: v.$blackish-10;
    }

    a {
        color: v.$blackish-50;
        transition: v.$transition;

        &:hover {
            color: v.$blackish-70;
            text-decoration: underline;
        }
    }

    .title {
        margin-bottom: 1rem;

        @include m.screen(sm) {
            margin-bottom: 1.5rem;
        }
    }

    ul {
        display: flex;

        flex-direction: column;

        gap: 0.5rem;
    }

    .areas {
        display: grid;


        gap: 4rem 1rem;

        @include m.screen("md") {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .area {
        &_row {
            grid-column: 1/-1;

            ul {
                display: grid;

                @include m.screen("md") {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}