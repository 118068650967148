@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.filters {
    overflow-x: auto;
    scrollbar-width: none;

    @include m.screen(sm) {
        justify-content: center;
    }

    &.disabled {
        pointer-events: none;

        opacity: 0.3;
    }
}

.filter-wrapper {
    padding: 4rem 1rem 1.5rem;
}

.filters,
.filters-list {
    display: flex;

    gap: .5rem;
}

.filters-list.--mobile-filters {
    flex-wrap: wrap;
}

.filter-pill {
    display: flex;

    align-items: center;
    justify-content: center;

    max-height: f.px2rem(42);

    .count-wrapper {
        display: flex;

        gap: .25rem;

        align-items: center;

        padding: 0.125rem .25rem 0.125rem .5rem;
        margin-left: .25rem;

        border-radius: 999px;

        button {
            display: flex;

            cursor: pointer;

            background-color: transparent;
        }
    }
}

.filter-pill.--active {
    // there is only 1 theme in the modal
    border: 1px solid v.$blackish !important;
}

.filter-category-title {
    position: sticky;
    top: 0;

    padding: .5rem 0;

    background-color: v.$white;
    border-bottom: 1px solid v.$blackish-10;
}

.custom-filter {
    margin-right: 2rem;
}

.order-filter-select {
    display: flex;

    grid-column: 1 / -1;

    gap: 0.7rem;

    align-items: center;
    justify-content: flex-end;

    width: 100%;
    margin-bottom: 1.5rem;

    select {
        position: relative;

        width: auto;
        padding: 0.75rem 1rem;

        color: v.$white;
        text-wrap: nowrap;

        -moz-appearance: none;

        /* Firefox */
        -webkit-appearance: none;

        /* Safari and Chrome */
        appearance: none;
        cursor: pointer;

        background-color: v.$blackish;
        background-repeat: no-repeat;
        background-position: left center;
        border-radius: 20px;

        transition: v.$transition;

        &:hover {
            color: v.$blackish;

            background-color: v.$white;
        }
    }
}