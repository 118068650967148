/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

.--theme-dark {
    .divider {
        background-color: v.$blackish-10;
    }

    &.--below-895,
    &.--above-895 {
        background-color: transparent;

        transition: background-color .3s ease;

        .menu-btn,
        .lang-selector {
            background-color: transparent;
        }

        &.--hovered {
            background-color: v.$white;

            .menu-btn,
            .lang-selector {
                color: v.$blackish;
            }
        }
    }

    &.header.--below-895 {
        &.--hovered {

            .burger::before,
            .burger::after,
            .burger div {
                background-color: v.$blackish;
            }
        }

        .burger::before,
        .burger::after,
        .burger div {
            background-color: v.$blackish;
        }

        [data-el="tab-option"].--active {
            background-color: v.$blackish-10;

            .small-text {
                color: v.$blackish;
            }
        }

        .login {
            a {
                border-color: v.$blackish;

                &:nth-of-type(2) {
                    color: v.$white;

                    background-color: v.$blackish;
                }
            }
        }
    }

    &.header.--above-895 {
        .menu-item.--active {
            .menu-btn {
                color: v.$white !important;

                background-color: v.$blackish;

            }

            &:hover {
                .menu-btn {
                    background-color: v.$blackish-80;
                }
            }
        }

        .menu-item.--hovered:not(.--active) {
            .menu-btn {
                color: v.$blackish !important;

                background-color: v.$blackish-10;
            }

        }

        .item-with-description {
            border-radius: v.$border-radius;

            transition: background-color .2s ease-in-out;


            &:hover {
                background-color: #E7E7E7;
            }

            &:has(.disabled) {
                a {
                    color: v.$blackish-50;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .block-2-wrapper {
            .divider {
                background-color: v.$blackish-10;
            }
        }

        .small-text {
            color: v.$blackish-60;
        }

        .lang-selector {
            cursor: pointer;
        }

        .lang-selector,
        .menu-btn {
            transition: background-color .2s ease-in-out;

            &:hover {
                background-color: v.$blackish-10;
            }
        }

        [class*="block-"] {
            .--highlighted {
                color: v.$white;

                background-color: v.$blackish;

                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: v.$blackish-80;
                }
            }
        }

        .long-text-content {
            color: v.$blackish;
        }
    }

    &.hero.--great-hero {
        --hero-color-gradient-start: #{v.$blackish};
        --hero-color-gradient-end: #{v.$blackish};
    }

    .btn-primary {
        color: v.$white;

        background-color: v.$blackish;

        &:hover {
            background-color: v.$blackish-80;
        }
    }

    .btn-secondary {
        color: v.$blackish;

        background-color: v.$white-10;
        backdrop-filter: blur(f.px2rem(12));
        border: 1px v.$blackish solid;

        @include m.screen(sm) {
            &:hover {
                background-color: v.$white;
            }
        }
    }

    .btn-tertiary {
        color: v.$blackish;

        background-color: v.$white-10;
        backdrop-filter: blur(f.px2rem(12));
        border: 1px v.$blackish-10 solid;
    }

    .title-hero,
    .title-great-hero,
    .paragraph-hero {
        color: v.$blackish;
    }

    .tag,
    .paragraph,
    .text-link {
        color: v.$blackish-60;
    }

    .--richtext a {
        color: v.$blackish-60 !important;
    }

    .title-1,
    .title-2,
    .title-3,
    .title-4,
    .title-5 {
        color: v.$blackish;
    }

    .paragraph {
        a {
            color: v.$blackish;
        }
    }

    // Cards
    &.card {
        background-color: v.$white-90;
        border: 1px solid v.$blackish-10;

        .tag {
            color: v.$blackish;

            background-color: v.$white-30;
            border-color: v.$blackish-10;
        }

        .chevron {
            border: 1px v.$blackish-10 solid;

            svg {
                path {
                    stroke: v.$blackish;
                }
            }
        }

        .paragraph {
            transition: v.$transition;
        }

        &:hover {
            .paragraph {
                color: v.$blackish;
            }
        }
    }

    &.card-simple {
        .card-simple__tag {
            color: v.$blackish-50;
        }
    }

    &.card-with-list-of-features .card-list-item {
        border: 1px v.$blackish-10 solid;

        .paragraph {
            color: v.$blackish;
        }
    }

    &.card-highlight-number {
        .card-highlight-number__title {
            color: v.$blackish;
        }
    }

    &.card-job-listing {
        background-color: v.$white-20;

        @include m.screen(sm) {
            &:hover {
                background-color: v.$white;
            }
        }

        svg {
            color: v.$blackish;
        }
    }

    &.card-with-block-image {
        .small-text {
            color: v.$blackish-50;
        }
    }

    &.text-feature {
        .title {
            border-left-color: v.$blackish;
        }

        .content {
            border-left-color: v.$blackish-20;
        }
    }

    &.card-new {
        .title {
            color: v.$blackish;
        }

        .author-date-wrapper {
            color: v.$blackish-70;
        }

        .tag {
            color: v.$white;

            background-color: v.$blackish-20;
        }
    }

    & {

        .card-emisiones,
        .card-emisiones-mobile {
            background-color: v.$white;

            .title {
                color: v.$blackish;
            }

            .paragraph {
                color: v.$blackish;
            }

            .card-header {
                border-bottom: 1px v.$blackish-10 solid;

                svg {
                    color: #11111133;
                }
            }

            .card-label-title,
            .item-max-capital-agente-colocador {
                color: v.$blackish-60;
            }

            .card-label-title {
                .label {
                    color: v.$blackish-60;
                }

                .value {
                    color: v.$blackish;
                }
            }

            .pill {
                height: f.px2rem(28);

                color: v.$blackish;

                background-color: v.$blackish-5;

                svg {
                    color: v.$white;
                }

                &.--pill-download {
                    color: white;

                    cursor: pointer;

                    background-color: v.$blackish;
                }
            }

            .accordion {
                .toggle-arrow {
                    path {
                        fill: v.$blackish;
                    }
                }
            }

            .chevron {
                svg {
                    path {
                        stroke: v.$blackish;
                    }
                }

                background-color: v.$white-20;
                border: 1px v.$blackish-10 solid;

                transition: v.$transition;

                @include m.screen(sm) {
                    &:hover {
                        color: v.$blackish;

                        background-color: v.$white;
                        border: 1px v.$blackish solid;
                    }
                }
            }
        }

        .card-emisiones-mobile {

            .dates-wrapper,
            .block-pills-container {
                border-bottom: 1px v.$blackish-10 solid;
            }

            &:hover {
                .paragraph {
                    color: v.$blackish !important;
                }
            }
        }
    }

    &.card-product {
        color: v.$white-70;
    }

    &.card-research {
        background-color: #F3F3F3E5;

        .card-simple__tag {
            color: #0A222B66;
        }

        .title-5 {
            color: v.$blackish;
        }

        .paragraph {
            color: v.$blackish-60;
        }
    }

    &.testimonial {
        .content-el {
            color: v.$blackish;

            .paragraph {
                color: v.$blackish;
            }
        }
    }

    // Accordion
    &.accordion__container {

        .title-5 {
            color: v.$white;
        }

        .paragraph {
            color: v.$white-70;
        }

        .accordion__visible-part {
            background-color: v.$blackish-30;
            border: 1px solid v.$white-10;

            &:hover {
                background-color: v.$blackish-60;
                border: 1px solid v.$white;

                .chevron {
                    border: 1.5px v.$white solid;
                }
            }

            &.--active {
                background-color: v.$blackish-60;
                border: 1px solid v.$white;

                .chevron {
                    border: 1.5px v.$white solid;
                }
            }

            .accordion {
                svg {
                    path {
                        stroke: v.$white;
                    }
                }
            }
        }

        .chevron {
            color: v.$white;

            background-color: transparent;
            border: 1.5px v.$blackish-10 solid;

            transition: v.$transition;

            @include m.screen(sm) {
                &:hover {
                    color: v.$white;

                    background-color: transparent;
                    border: 2px v.$white solid;
                }
            }
        }
    }

    // Tabs
    &.tabs {
        border-bottom: 1px solid v.$blackish-10;

        .tabs-block {
            background: v.$blackish-5;
            border: 1px solid v.$blackish-10;
        }

        .line {
            background-color: v.$blackish-10;
        }

        .slider {
            background-color: v.$white;
        }

        li {
            color: v.$blackish-60;

            &.--tab-active {
                color: v.$blackish
            }
        }

        .filter-pill {
            border: 1px solid v.$blackish-20 !important;

            @include m.screen(sm) {
                &:hover {
                    color: v.$blackish;

                    background-color: v.$white;
                    border: 1px solid v.$blackish !important;
                }
            }
        }

        .filter-pill.--border {
            padding-right: 10px;

            background-color: v.$white;
            border: 1px solid v.$blackish !important;

            transition: v.$transition;
        }
    }

    // Inputs
    &.form {
        .form-input {
            color: v.$blackish;

            background-color: v.$white-30;
            border: 1px solid v.$blackish-20;

            &:hover,
            &:not(:placeholder-shown):hover {
                background-color: v.$white-50;
                border: 1px solid v.$blackish-50;
            }

            &:focus {
                background-color: v.$white;
                border: 1px solid v.$blackish;
            }

            &:focus+label {
                color: v.$blackish-60;
            }

            &:not(:placeholder-shown) {
                background-color: v.$white;

                &+label {
                    color: v.$blackish-60;
                }
            }

        }

        .optional {
            color: v.$blackish-40;
        }

        .form-input-label {
            color: v.$blackish;
        }

        .form-input-select {
            color: v.$blackish;

            background-color: v.$white-30;
            border: 1px solid v.$blackish-20;

            &:hover {
                background-color: v.$white-50 !important;
                border: 1px solid v.$blackish-50 !important;
            }

            &:not(.interacted):invalid {
                /* Default state, no interaction */
                background-color: v.$white-30;
            }

            &:valid {
                background-color: v.$white;
                border: 1px solid v.$blackish-20;

                /* Option selected */
            }
        }

        .form-input.--error,
        .form-input-select.--error {
            color: #FF4D4F;

            background-color: rgba(255, 245, 245, 90%) !important;
            border: 1px #FF4D4F solid;

            &:hover {
                border: 1px #FF4D4F solid !important;
            }

            &+label {
                color: v.$blackish-60 !important;
            }
        }
    }

    .count-wrapper {
        color: v.$white;

        background-color: v.$blackish;
    }

    // Footer
    &.footer {
        .paragraph {
            color: v.$white;
        }

        .locale-link,
        .small-text {
            color: v.$white-50;

            @include m.screen(sm) {
                &:hover {
                    color: v.$white;
                }
            }
        }

        .locale-link {
            .dropdown-footer {
                color: v.$white-50;
            }

            .safari-caret {
                path {
                    stroke: v.$white-50;
                }
            }
        }

        li {
            .paragraph {
                color: v.$white-50;
            }
        }

        .chevron {
            color: v.$white;
        }

        .accordion {
            &>.paragraph {
                color: v.$white;
            }
        }

        .accordion__visible-part {
            border-top-color: v.$white-20;
        }

        .block-2 {
            color: v.$white;

            border-bottom: 1px v.$white-20 solid;
        }

        .block-3 {
            .content-box {
                .small-text:nth-of-type(1) {
                    color: v.$white;
                }

                .small-text:nth-of-type(2) {
                    color: v.$white-50;
                }
            }
        }
    }

    // Modal
    &.modal-react {
        .chevron {
            border: 1px v.$blackish-10 solid;
        }

        .filter-pill:not(.--active) {
            color: v.$blackish;

            background-color: v.$blackish-5 !important;
            border: 1px solid transparent !important;

            @include m.screen(sm) {
                &:hover {
                    background-color: v.$white !important;
                    border: 1px solid v.$blackish !important;
                }
            }
        }

        .filter-pill.--active {
            color: v.$white;

            background-color: v.$blackish !important;
            border: 1px solid v.$blackish !important;
        }
    }

    // Filters
    .filters {
        .filter-btn {
            color: v.$blackish;
        }
    }

    svg.icon {
        path {
            stroke: v.$blackish !important;
            stroke-width: 1px !important;
        }
    }

    &.header {
        .logo {
            svg {
                color: v.$blackish !important;
            }
        }

        .button-menu-header {
            color: v.$blackish;
        }
    }

    // Carousel arrows
    &.arrows-container {
        .splide__arrows {
            border-color: v.$white-20 !important;

            .splide__arrow {
                background-color: transparent;

                .chevron {
                    color: v.$white;

                    background-color: transparent;
                    border: 1px v.$white solid;

                    transition: v.$transition;

                    @include m.screen(sm) {
                        &:hover {
                            color: v.$blackish;

                            background-color: v.$white;
                            border: 1px v.$white solid;
                        }
                    }
                }

                &:disabled {
                    .chevron {
                        color: v.$white-20;

                        cursor: auto;

                        border: 1px v.$white-30 solid;

                        &:hover,
                        &:active {
                            color: v.$white-20;

                            background: transparent;
                            border: 1px v.$white-30 solid;
                        }
                    }
                }
            }
        }

        .splide__progress__bar {
            background-color: v.$white-10;
        }
    }
}

.--theme-light {
    .divider {
        background-color: v.$white-30;
    }

    &.header.--below-895 {
        &.--hovered {

            .burger::before,
            .burger::after,
            .burger div {
                background-color: v.$blackish;
            }

            svg {
                color: v.$blackish;
            }
        }

        .burger::before,
        .burger::after,
        .burger div {
            background-color: v.$white;
        }

        .lang-selector {
            color: v.$white;

            svg {
                * {
                    stroke: v.$white;
                }
            }
        }

        svg {
            color: v.$white;
        }

        .nav-mobile-sublist {
            a {
                color: v.$blackish;
            }
        }

        [data-el="tab-option"].--active {
            background-color: v.$blackish;

            .small-text {
                color: v.$white !important;
            }
        }

        .login {
            a {
                border-color: v.$blackish;

                &:nth-of-type(2) {
                    color: v.$white;

                    background-color: v.$blackish;
                }
            }
        }
    }

    &.header.--above-895 {
        .menu-item.--active {
            .menu-btn {
                color: v.$blackish !important;

                background-color: v.$white;

            }

            &:hover {
                .menu-btn {
                    background-color: v.$blackish-10;
                }
            }
        }

        .menu-item.--hovered:not(.--active) {
            .menu-btn {
                color: v.$blackish !important;

                background-color: v.$blackish-10;
            }

        }

        .item-with-description {
            transition: background-color .2s ease-in-out;


            &:hover {
                background-color: #E7E7E7;
            }

            &:has(.disabled) {
                a {
                    color: v.$blackish-50;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .block-2-wrapper {
            .divider {
                background-color: v.$blackish-10;
            }
        }

        .small-text {
            color: v.$blackish-60;
        }

        .lang-selector {
            color: v.$white;

            cursor: pointer;

            svg {
                * {
                    stroke: v.$white;
                }
            }
        }

        .lang-selector,
        .menu-btn {
            transition: background-color .2s ease-in-out;

            &:hover {
                background-color: v.$blackish-10;
            }
        }

        [class*="block-"] {
            .--highlighted {
                color: v.$blackish;

                background-color: v.$white;

                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: v.$blackish-10;
                }
            }
        }
    }

    &.hero.--great-hero {
        --hero-color-gradient-start: #{v.$white};
        --hero-color-gradient-end: #{v.$white};
    }

    .btn-primary {
        color: v.$blackish;

        background-color: v.$white;

        @include m.drop-shadow;

        @include m.screen(sm) {
            backdrop-filter: blur(f.px2rem(12));

            &:hover {
                background-color: v.$white-80;
            }
        }
    }

    .btn-secondary {
        color: v.$white;

        background-color: v.$blackish-10;
        backdrop-filter: blur(f.px2rem(12));
        border: 1px v.$white-10 solid;

        @include m.screen(sm) {
            &:hover {
                background-color: v.$blackish;
            }
        }
    }

    .btn-tertiary {
        color: v.$white;

        background-color: v.$blackish-10;
        backdrop-filter: blur(f.px2rem(12));
        border: 1px v.$white-10 solid;
    }

    .title-hero,
    .title-great-hero,
    .paragraph-hero,
    .text-link {
        color: v.$white;
    }

    .tag,
    .paragraph {
        color: v.$white-70;
    }

    .title-1,
    .title-2,
    .title-3,
    .title-4,
    .title-5 {
        color: v.$white;
    }

    .paragraph {
        a {
            color: v.$white;
        }
    }

    .--richtext a {
        font-weight: 400;
        color: v.$blackish-60 !important;
    }

    // Cards
    &.card {
        background-color: v.$blackish-30;
        border: 1px v.$white-10 solid;

        .tag {
            color: v.$white;

            background-color: v.$blackish-10;
            border-color: v.$white-10;
        }

        .chevron {
            color: v.$blackish;

            background-color: v.$blackish-10;
            border: 1px solid v.$white-10;

            svg {
                path {
                    stroke: v.$white;
                }
            }
        }

        .paragraph {
            transition: v.$transition;
        }

        &:hover {
            .paragraph {
                color: v.$white;
            }
        }
    }

    &.card-simple {
        .card-simple__tag {
            color: v.$white-70;
        }
    }

    &.card-with-list-of-features .card-list-item {
        border: 1px v.$white-10 solid;

        .paragraph {
            color: v.$white;
        }
    }

    &.card-highlight-number {
        .card-highlight-number__title {
            color: v.$white;
        }
    }

    &.card-job-listing {
        background-color: v.$blackish-30;

        @include m.screen(sm) {
            &:hover {
                background-color: v.$blackish-10;
            }
        }

        svg {
            color: v.$white-70;
        }
    }

    &.card-new {
        .title {
            color: v.$white;
        }

        .author-date-wrapper {
            color: v.$white-60;
        }

        .tag {
            background-color: v.$white-10;
        }
    }

    & {

        .card-emisiones,
        .card-emisiones-mobile {
            background-color: v.$blackish-80;

            .title {
                color: v.$white;
            }

            .paragraph {
                color: v.$white;
            }

            .card-header {
                border-bottom: 1px v.$white-10 solid;

                svg {
                    color: v.$white-70;
                }
            }

            .card-label-title,
            .item-max-capital-agente-colocador {
                color: v.$white-70;
            }

            .card-label-title {
                .label {
                    color: v.$white-70;
                }

                .value {
                    color: v.$white;
                }
            }

            .pill {
                height: f.px2rem(28);

                color: v.$white;

                background-color: v.$white-10;

                svg {
                    color: v.$blackish;
                }

                &.--pill-download {
                    color: v.$blackish;

                    background-color: v.$white;
                }
            }

            .accordion {
                .toggle-arrow {
                    path {
                        fill: v.$blackish;
                    }
                }
            }

            .chevron {
                svg {
                    color: v.$blackish;
                }

                background-color: v.$white-20;
                border: 1px v.$blackish-10 solid;

                transition: v.$transition;

                @include m.screen(sm) {
                    &:hover {
                        color: v.$blackish;

                        background-color: v.$white;
                        border: 1px v.$blackish solid;
                    }
                }
            }

            .card-emisiones-item {
                color: v.$white-70;
            }

            .--pill-green {
                color: v.$blackish;
            }
        }
    }

    &.card-with-block-image {
        .small-text {
            color: v.$white-60;
        }
    }

    &.text-feature {
        .title {
            border-left-color: v.$white;
        }

        .content {
            border-left-color: v.$white-20;
        }
    }

    &.card-research {
        .title-5 {
            font-weight: 500;
        }

        .paragraph {
            color: v.$white-60;
        }
    }

    &.testimonial {
        .content-el {
            color: v.$white;

            .paragraph {
                color: v.$white;
            }
        }
    }

    // Accordion
    &.accordion__container {

        .title-5 {
            color: v.$blackish;
        }

        .paragraph {
            color: v.$blackish-60;
        }

        .accordion__visible-part {
            background-color: v.$white-80;

            @include m.backdrop-blur;

            border: 1px solid v.$blackish-10;

            &:hover {
                border: 1px solid v.$blackish;
            }

            &.--active {
                background-color: v.$white;
                border: 1px solid v.$blackish;

                .chevron {
                    color: v.$blackish;

                    border: 1.5px v.$blackish solid;
                }
            }

            .chevron {
                color: v.$blackish-20;

                background-color: v.$white-20;
                border: 1.5px v.$blackish-10 solid;

                transition: v.$transition;

            }

            @include m.screen(sm) {
                &:hover {
                    .chevron {
                        color: v.$blackish;

                        background-color: v.$white;
                        border: 1.5px v.$blackish solid;
                    }
                }
            }

        }
    }

    // Tabs
    &.tabs {
        border-bottom: 1px solid v.$white-10;

        .tabs-block {
            background: v.$blackish-20;
            border: 1px solid v.$white-10;
        }

        .line {
            background-color: v.$white-10;
        }

        .slider {
            background-color: v.$white-20;
        }

        li {
            color: v.$white-60;

            &.--tab-active {
                color: v.$white;
            }
        }

        .filter-pill {
            border: 1px solid v.$blackish-20 !important;

            @include m.screen(sm) {
                &:hover {
                    background-color: v.$white;
                    border: 1px solid v.$blackish !important;
                }
            }
        }

        .filter-pill.--border {
            background-color: v.$white;
            border: 1px solid v.$blackish !important;

            transition: v.$transition;
        }
    }

    // Inputs
    &.form {
        .form-input {
            color: v.$white;

            background-color: v.$blackish-10;
            border: 1px solid v.$white-20;

            &:focus {
                background-color: v.$blackish-80;
                border: 1px solid v.$white-80;
            }

            &:hover,
            &:not(:placeholder-shown):hover {
                background-color: v.$blackish-30;
                border: 1px solid v.$white-70;
            }

            &:not(:placeholder-shown) {
                background-color: v.$blackish-90;
                border: 1px solid v.$white-20;
            }

            &:focus+label {
                color: v.$white-50;
            }

            &:not(:placeholder-shown) {
                &+label {
                    color: v.$white-50;
                }
            }

        }

        .optional {
            color: v.$white-40;
        }

        .form-input-label {
            color: v.$white;
        }

        .form-input-select {
            color: v.$white;

            background-color: transparent;
            border: 1px solid v.$white-20;

            &~svg {
                color: v.$white;
            }

            &:hover {
                background-color: v.$blackish-30 !important;
                border: 1px solid v.$white-70 !important;
            }

            &:not(.interacted):invalid {
                /* Default state, no interaction */
                background-color: v.$blackish-10;
            }

            &:valid {
                background-color: v.$blackish-90;
                border: 1px solid v.$white-20;

                /* Option selected */
            }
        }

        .form-input.--error,
        .form-input-select.--error {
            color: #FF4D4F;

            background-color: rgba(70, 0, 1, 90%) !important;
            border: 1px #FF4D4F solid;

            &:hover {
                border: 1px #FF4D4F solid !important;
            }

            &+label {
                color: v.$white-50 !important;
            }
        }

        .form-disclaimer {
            color: v.$white-60;
        }
    }

    &.job-listing {
        .upload-cv {
            a {
                color: v.$white;
            }
        }
    }

    // Footer
    &.footer {
        .paragraph {
            color: v.$blackish;
        }

        li {
            .paragraph {
                color: v.$blackish-50;
            }
        }
    }

    .count-wrapper {
        color: v.$white;

        background-color: v.$blackish;
    }

    // Modal
    &.modal-react {
        .chevron {
            border: 1px v.$blackish-10 solid;
        }
    }

    svg.icon {
        path {
            stroke: v.$white !important;
            stroke-width: 1px !important;
        }
    }

    &.header {
        &.--hovered {
            .logo {
                svg {
                    color: v.$blackish;
                }
            }

            .--highlighted {
                color: v.$white;

                background-color: v.$blackish;

                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: v.$blackish-80;
                }
            }

            .divider {
                background-color: v.$blackish-10;
            }
        }

        .logo {
            svg {
                color: v.$white;
            }
        }

        .button-menu-header {
            color: v.$white;
        }
    }

    // Carousel arrows
    &.arrows-container {
        .splide__arrows {
            border-color: v.$blackish-20 !important;

            .splide__arrow {
                background-color: transparent;

                .chevron {
                    color: v.$blackish;

                    background-color: transparent;
                    border: 1px v.$blackish solid;

                    transition: v.$transition;

                    @include m.screen(sm) {
                        &:hover {
                            color: v.$white;

                            background-color: v.$blackish;
                            border: 1px v.$blackish solid;
                        }
                    }
                }

                &:disabled {
                    .chevron {
                        color: v.$blackish-20;

                        cursor: auto;

                        border: 1px v.$blackish-20 solid;

                        &:hover,
                        &:active {
                            color: v.$blackish-20;

                            background: transparent;
                            border: 1px v.$blackish-20 solid;
                        }
                    }
                }
            }
        }

        .splide__progress__bar {
            background-color: v.$blackish-10;
        }
    }

    .long-text-content {
        color: v.$white;
    }
}