@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;
@use "../base/fonts" as fonts;

.long-text-content {
    .content-wrapper {
        grid-column: 1/-1;

        @include m.screen(sm) {
            grid-column: 3/11;
        }
    }

    * {
        text-wrap: wrap;
    }

    h2.title-4 {
        margin: 1.25rem 0 0;

        font-weight: 600;

        @include m.screen(sm) {
            margin: 2rem 0 0;
        }
    }

    b {
        color: v.$blackish;
    }

    a {
        color: v.$blackish;
        text-decoration: underline;
    }

    ul {
        display: flex;

        flex-direction: column;

        gap: 0.5rem;

        padding-left: 2rem;

        list-style-type: disc;

        li {
            &::marker {
                font-size: 20px;
                line-height: 0;
                color: v.$blackish;
            }

            ul {
                list-style-type: circle;

                li {
                    ul {
                        list-style: square;
                    }
                }
            }
        }
    }

    blockquote {
        padding-left: 2.5rem;
        margin: 1.5rem 0;

        color: v.$blackish-70;

        border-left: 1px solid v.$blackish;

        @include fonts.title-4-clamp-cq;

        @include m.screen(sm) {
            padding-left: 4rem;
            margin: 3rem 0;
        }
    }

    p:has(~ p) {
        margin-bottom: .5rem;
    }
}