/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable selector-id-pattern */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

@use "../base/fonts" as fonts;

#cotizaciones_root {
    .component-container.--react {
        padding: 0;

        border-radius: 0;

        @include m.screen(sm) {
            padding: f.px2rem(8) f.px2rem(16);

            border-radius: v.$border-radius;
        }

        .component-wrapper {
            border-radius: 0;

            @include m.screen(sm) {
                border-radius: v.$border-radius;
            }
        }

        .component-container-bg-el,
        .component-container-content {
            @media (max-width: "768px") {
                border-radius: 0 !important;
            }
        }
    }

    .hero.--cotizaciones {
        height: auto;

        @include m.screen(sm) {
            height: max-content;
            min-height: max-content;
            max-height: none;
        }

        .flex-content {
            padding-top: 9.25rem;

            @include m.screen(sm) {
                padding-top: 14rem;
            }
        }

        .tag {
            margin: 0;

            color: v.$white-70;
        }

        .title-1 {
            margin-bottom: 0;

            color: v.$white;
        }

        .hero-header {
            display: flex;

            align-items: flex-end;
            justify-content: space-between;

            @include m.grid;
        }

        .text-content {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(10);

            margin-bottom: 0;

            @include m.screen(sm) {
                grid-column: span 6;

                gap: 1rem;
            }
        }

        .search-bar {
            display: none;

            @include m.screen(sm) {
                display: block;

                grid-column: span 6;
            }
        }
    }

    .carousel-products {
        .title-1 {
            margin-bottom: f.px2rem(40);

            @include m.screen(sm) {
                margin-bottom: f.px2rem(64);
            }
        }
    }

    .open-your-account {
        .tag {
            margin: 0 auto;
        }

        .title {
            margin-bottom: 1rem;
        }

        li {
            flex-direction: row;

            .paragraph {
                margin-top: 0;
            }
        }
    }

    .cards-wrapper {
        display: grid;

        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column: span 4;

        gap: f.px2rem(10);

        @include m.screen(sm) {
            display: flex;

            flex-direction: column;
        }
    }

    .graph-cards-container {
        --bg-color: #{v.$blackish} !important;

        @include m.grid(1.5rem);

        .graph-wrapper {
            grid-column: 1/-1;

            overflow: hidden;

            color: v.$white-70;

            background-color: v.$blackish;
            border: 1px solid v.$white-10;
            border-radius: v.$border-radius;

            @include m.screen(sm) {
                grid-column: span 8;
            }
        }
    }

    .marquee {
        margin-top: f.px2rem(40);
        margin-bottom: f.px2rem(40);

        // to se the others slides
        overflow: hidden;

        @include m.screen(sm) {
            margin-top: f.px2rem(64);
            margin-bottom: f.px2rem(64);
        }
    }

    .cot-base-card {
        grid-column: 1/-1;

        padding: f.px2rem(10);

        border-radius: v.$border-radius-sm;

        @include m.screen(md) {
            padding: 1rem;
        }

        img {
            border-radius: 999px;
        }
    }

    .cot-marquee-card {
        min-width: f.px2rem(123);

        background-color: v.$blackish;
        border: 1px solid v.$white-10;
        border-radius: v.$border-radius;

        @include m.screen(sm) {
            display: flex;

            align-items: center;
            justify-content: space-between;

            min-width: f.px2rem(270);
        }

        .text {
            display: flex;
            flex-direction: column;
        }

        .label {
            margin-bottom: f.px2rem(4);

            color: v.$white;
        }

        .sublabel {
            margin-bottom: f.px2rem(4);
            color: v.$white;
        }

        .value {
            margin-bottom: f.px2rem(4);

            color: v.$white;
        }

        .ticker-logo {
            margin-bottom: 8px;

            @include m.screen(sm) {
                margin-right: 8px;
                margin-bottom: 0;
            }
        }
    }

    .cot-card-graph {
        .title {
            text-align: center;
        }

        .echarts-for-react {
            width: 100%;
        }
    }

    .cot-card-with-tooltip {
        height: max-content;

        background-color: v.$blackish;
        border: 1px solid v.$white-10;
        border-radius: v.$border-radius;
        grid-column: span 2;

        @include m.screen(sm) {

            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                grid-column: 1/-1;
            }

            display: grid;

            grid-template-columns: 2fr 1fr;

            gap: 1rem;
        }

        .values-container {
            display: flex;

            flex-direction: column;

            gap: 2rem;

            @include m.screen(md) {
                flex-direction: row;

                align-items: flex-end;
            }
        }

        .variation-tooltip {
            display: flex;

            align-items: center;
            justify-content: space-between;

            margin-top: f.px2rem(12);

            .variation {
                p {
                    @include fonts.title-5-clamp-cq;
                }
            }

            @include m.screen(md) {
                flex-direction: column;

                align-items: flex-end;

                margin-top: 0;
            }
        }

        .var-icon {
            display: none;

            @include m.screen(sm) {
                display: block;
            }
        }

        .label {
            color: v.$white-60;
            text-transform: uppercase;
        }

        .value {
            color: v.$white;
        }

        .title {
            margin-bottom: f.px2rem(4);

            color: v.$white;

            @include m.screen(md) {
                margin-bottom: .5rem;
            }
        }

        .value-wrapper:has(~ .value-wrapper) {
            margin-bottom: f.px2rem(4);

            @include m.screen(md) {
                margin-bottom: 0;
            }
        }
    }

    .cot-card-with-icon {
        display: grid;

        // grid-template-rows: 1fr;
        grid-template-columns: 100%;

        gap: f.px2rem(4);

        min-height: f.px2rem(70);

        background-color: v.$white;
        border: 1px solid v.$blackish-10;
        border-radius: v.$border-radius;

        @include m.screen(sm) {
            // grid-template-columns: auto 1fr 1fr;
            grid-template-columns: min-content 60% auto;

            gap: f.px2rem(0);

            &.cauciones {
                grid-template-columns: min-content auto auto !important;
            }
        }


        .labels {
            text-transform: capitalize;

            .small-text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                // max-width: 123px;
                // @include m.screen(sm) {
                //     max-width: 130px;
                // }
                // @media (min-width: (895px)) {
                //     max-width: 123px;
                // }
                // @media (min-width: (1180px)) {
                //     max-width: 205px;
                // }
            }


            @include m.screen(sm) {
                margin-left: f.px2rem(12);
            }
        }

        .values {
            align-items: flex-start;
            justify-content: center;

            .price {
                display: flex;
                align-items: flex-end;
                p:first-of-type {
                    @include m.font-size(10, 500, 1, 2);
                    color: v.$blackish-60;
                    padding-right: f.px2rem(2);
                }
            }

            .pill {
                background-color: #F3F3F3;
                border: 1px solid v.$blackish-10;
                padding: 6px 8px;
                border-radius: v.$border-radius;
                p {
                    @include m.font-size(10, 500, 1, 2);
                    color: v.$blackish;
                    text-transform: uppercase;
                }
            }

            @include m.screen(sm) {
                align-items: flex-end;
            }
        }

        .values,
        .labels {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(4);
        }

        .small-text {
            color: v.$blackish-60;
        }
    }

    .cot-cards-with-title {
        .title-5 {
            margin-bottom: f.px2rem(24);
        }

        .cards-container {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(8);

            a:hover {
                color: v.$blackish;
            }
        }
    }

    .cot-slider {
        width: 100%;
    }

    .cot-tabs {
        .cot-tab-content {
            display: grid;

            gap: f.px2rem(40);

            &.--cols-1 {
                grid-template-columns: repeat(1, minmax(0, 1fr));

                &.--below-895-flex {
                    display: flex;

                    flex-direction: column;

                    gap: f.px2rem(40);
                }
            }

            &.--cols-2 {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            &.--cols-3 {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            &.--cols-4 {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            .btn {
                @include m.screen(sm) {
                    grid-column: 1/-1;

                    margin: 0 auto;
                }
            }
        }
    }

    .cot-search-btn {
        display: block;

        grid-column: -1;

        color: v.$white;

        background-color: v.$blackish-80;
        border: 1px solid v.$white-20;

        @include m.screen(sm) {
            display: none;
        }
    }

    .variation {
        display: flex;

        gap: f.px2rem(8);

        align-items: center;

        @include m.screen(sm) {
            justify-content: flex-end;
        }
    }

    .variation-big {
        display: flex;

        gap: f.px2rem(8);

        align-items: center;

        svg {
            width: f.px2rem(18);
            height: f.px2rem(18);

            @include m.screen(sm) {
                width: f.px2rem(24);
                height: f.px2rem(24);
            }
        }
    }

    .only-text {
        .title {
            margin-top: f.px2rem(40);

            @include m.screen(sm) {
                margin-top: f.px2rem(64);
            }
        }

        li {
            display: flex;

            gap: f.px2rem(8);

            align-items: center;
            justify-content: center;

            color: v.$white;
        }

        &.with-badges {
            .text-content {
                display: flex;

                flex-direction: column;

                @include m.screen(sm) {
                    flex-direction: row;

                    align-items: flex-end;
                    justify-content: space-between;
                }
            }

            .title {
                margin-bottom: 0 !important;
            }

            .badges {
                display: flex;

                gap: .5rem;

                align-items: center;

                margin-top: 1rem;

                .tag {
                    margin-bottom: 0;
                }
            }
        }
    }

    .product-page-header {
        margin-top: f.px2rem(148);

        @include m.screen(sm) {
            margin-top: f.px2rem(160);
        }
    }

    .cot-product-table {
        &__symbol {
            display: flex;

            gap: .5rem;

            align-items: center;

            // justify-content: center;
        }

        .ant-table-row {
            cursor: pointer;
        }

        .variation {
            justify-content: flex-start;
        }
    }

    .cot-more-info-and-search {
        display: flex;

        justify-content: space-between;

        padding: f.px2rem(18);
        margin-bottom: 1rem;

        border: 1px solid v.$blackish-10;

        .more-info {
            display: flex;

            gap: f.px2rem(8);

            align-items: center;
        }
    }

    .cot-hero-product-detail {
        .ant-breadcrumb {
            padding-bottom: .5rem;
            margin-top: f.px2rem(100);
            margin-bottom: 2rem;

            border-bottom: 1px solid v.$white-20;

            @include m.screen(sm) {
                margin-top: f.px2rem(160);
                margin-bottom: 4rem;
            }

            .ant-breadcrumb-link {
                color: v.$white !important;

                a {
                    color: v.$white !important;
                }

                text-transform: capitalize;


            }

            .ant-breadcrumb-separator {
                color: v.$white-60;
            }
        }

        .content-wrapper {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(20);

            @include m.screen(sm) {
                flex-direction: row;
            }
        }

        .cot-product-name {
            display: flex;

            flex: 1 1 auto;

            gap: f.px2rem(10);

            @include m.screen(sm) {
                gap: f.px2rem(16);
            }

            img {
                width: f.px2rem(32);
                height: f.px2rem(32);

                @include m.screen(sm) {
                    width: f.px2rem(72);
                    height: f.px2rem(72);
                }
            }

            .title-2 {
                color: v.$white;
            }

            .title-5 {
                color: v.$white-60;
            }
        }

        .cot-product-value {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(2);

            align-items: center;

            padding-top: f.px2rem(24);
            margin-top: f.px2rem(24);

            border-top: 1px solid v.$white-20;

            @include m.screen(sm) {
                align-items: flex-end;

                padding-top: 0;
                margin-top: 0;

                border-top: none;
            }

            .value-wrapper {
                color: v.$white;
            }

            .var-label {
                color: v.$white-60;
                text-transform: uppercase;
            }

            .var-type {
                color: v.$white-40;
            }
        }

        .product-info-values-wrapper {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(24);

            @include m.screen(sm) {
                flex-direction: row;

                gap: f.px2rem(40);
            }

            .divider {
                width: 1px;
                height: 100%;

                background-color: v.$white-20;
            }
        }

        .refresh-content {
            display: flex;

            gap: .25rem;

            align-items: center;
            justify-content: flex-end;


            margin: f.px2rem(8) 0;

            color: v.$white-60;

            @include m.screen(sm) {
                grid-column: 11/12;
            }

            .small-text {
                text-wrap: nowrap;
            }
        }

        .badge-container {
            display: flex;

            justify-content: flex-end;

            @include m.screen(sm) {
                margin: 0;
            }

            .ant-tag {
                margin-inline-end: 0;
            }
        }

        .cot-hero-footer {
            align-items: center;

            padding-top: 1rem;
            margin-top: f.px2rem(48);
            margin-bottom: f.px2rem(32);

            border-top: f.px2rem(1) solid v.$white-20;

            @include m.screen(sm) {
                @include m.grid;

                padding: f.px2rem(24) 0;
                margin-bottom: f.px2rem(48);
            }

            .paragraph,
            .indicators {
                grid-column: 1/-1;
            }

            .paragraph {
                color: v.$white-70;

                @include m.screen(sm) {
                    grid-column: span 8;
                }
            }

            .indicators {
                @include m.screen(sm) {
                    grid-column: 12;
                }
            }

            @include m.screen(sm) {
                margin-bottom: f.px2rem(18);
            }
        }
    }

    .cot-buy-sell-values-table {
        /* Bordes redondeados */
        // overflow: hidden;

        /* Fondo negro */
        color: v.$white-60;

        /* Texto blanco */
        border-radius: 8px;

        /* Asegura que los bordes redondeados se apliquen a la tabla también */
        table {
            width: 100%;

            /* La tabla ocupa todo el ancho del contenedor */
            border-collapse: collapse;

            /* Elimina el espacio entre las celdas */
        }

        th,
        td {
            padding: 12px;

            /* Espaciado dentro de las celdas */
            text-align: left;

            /* Alineación del texto */
        }

        thead {
            border-bottom: 1px solid v.$white-20;
        }

        tr:nth-child(odd) {
            border-bottom: 1px solid v.$white-20;
        }

        .index {
            font-weight: 600;
            color: v.$white;
        }
    }

    .cot-collapsable-content,
    .cot-stats-row {
        background-color: #F3F3F3;

        .ant-collapse-header,
        .ant-collapse-content-box {
            padding-right: 0;
            padding-left: 0;
        }

        .ant-collapse-content-box {
            padding-bottom: 0;
        }

        .collapse-content {
            display: grid;

            grid-template-rows: repeat(11, minmax(auto, 1fr));
            grid-template-columns: repeat(1, minmax(auto, 1fr));
            grid-auto-flow: column;

            gap: f.px2rem(4);

            @include m.screen(sm) {
                grid-template-rows: repeat(5, minmax(auto, 1fr));
                grid-template-columns: repeat(2, minmax(auto, 1fr));

                column-gap: f.px2rem(40);
            }

            &-item {
                display: flex;

                align-items: center;
                justify-content: space-between;
            }
        }

        .collapse-footer {
            display: flex;

            justify-content: flex-end;

            padding: f.px2rem(16) 0;

            @include m.screen(sm) {
                padding: f.px2rem(24) 0;
                margin-top: 1rem;
            }
        }
    }

    .cot-stats-grid {
        display: grid;

        grid-template-columns: repeat(2, minmax(auto, 1fr));

        gap: f.px2rem(16);

        .pill-border {
            grid-column: 2/3;

            @include m.screen(sm) {
                grid-column: 7/8;
            }
        }

        &.--fondos {
            @include m.screen(sm) {
                grid-template-columns: repeat(7, minmax(auto, 1fr));
            }
        }
    }

    .cot-stats-row {
        padding: f.px2rem(40) 0;

        @include m.screen(sm) {
            padding: f.px2rem(16) 0;
        }

        .cot-stats-grid {
            @include m.screen(sm) {
                grid-template-columns: repeat(5, minmax(auto, 1fr));
            }
        }
    }

    .cot-collapsable-content {
        .cot-stats-row {
            .cot-stats-grid {
                @include m.screen(sm) {
                    grid-template-columns: repeat(6, minmax(auto, 1fr));
                }
            }
        }
    }


    .cot-stats {
        display: flex;

        flex-direction: column;

        gap: f.px2rem(4);

        .title {
            margin-bottom: 0;

            color: v.$blackish-60;
        }

        .value {
            display: flex;

            gap: f.px2rem(4);

            align-items: center;

            .tag {
                color: v.$blackish-60;
            }
        }
    }

    .cot-prod-detail-tabs {
        margin-top: f.px2rem(16);

        @include m.screen(sm) {
            margin-top: f.px2rem(24);
        }
    }

    .cot-tabs-block {
        position: relative;
        z-index: 10;

        display: flex;

        flex: 1 0 auto;
        flex-direction: row;

        width: max-content;
        padding: f.px2rem(2);
        margin-right: auto;
        margin-bottom: f.px2rem(48);
        margin-left: auto;

        border: 1px solid v.$white-20;
        border-radius: 100px;

        @include m.screen(sm) {
            margin-right: 0;
        }

        li {
            position: relative;

            flex: auto;

            padding: f.px2rem(12) f.px2rem(24);
            margin: 0;

            font-weight: 600;
            text-align: center;
            list-style: none;

            cursor: pointer;
        }

        .slider {
            position: absolute;
            top: 0;
            top: 50%;
            left: 0;

            height: calc(100% - f.px2rem(4));

            background: v.$white-20;
            border-radius: 100px;

            transform: translateY(-50%);
        }

        .tab-item {
            color: v.$white-60;

            transition: v.$transition;
        }

        .--tab-active {
            color: v.$white;

            background-color: v.$white-20;
            border-radius: 999px;
        }

        .tabs-block {
            z-index: 0;
        }
    }

    .cot-prod-detail-tabs {
        margin-top: f.px2rem(20);

        &__header {
            display: flex;

            flex-direction: column;

            gap: f.px2rem(10);

            padding: 2rem 0;

            @include m.screen(sm) {
                flex-direction: row;

                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .cot-product-comparator {
        .comparator-controls {
            display: grid;

            grid-template-columns: repeat(1, minmax(auto, 1fr));

            row-gap: f.px2rem(12);

            margin-top: f.px2rem(16);
            margin-bottom: f.px2rem(40);

            @include m.screen(sm) {
                grid-template-columns: repeat(3, minmax(auto, 1fr));

                gap: f.px2rem(0) f.px2rem(20);

                margin-top: f.px2rem(24);
                margin-bottom: f.px2rem(64);
            }
        }

        .comparator-control {
            display: flex;

            flex-direction: column;

            gap: 1rem;

            padding: f.px2rem(16) f.px2rem(10);

            background-color: #F3F3F3;
            border-radius: v.$border-radius;
        }

        .ant-select-selection-item {
            color: v.$blackish
        }

        .ant-select-dropdown {
            background-color: v.$white !important;
        }
    }

    .cot-elem-container {
        margin-top: f.px2rem(40);

        @include m.screen(sm) {
            margin-top: f.px2rem(64);
        }

        &-title {
            margin-bottom: f.px2rem(24);

            &:has(~ p) {
                margin-bottom: .5rem;
            }
        }

        &-subtitle {
            color: v.$blackish-60;
        }
    }

    .cot-var-table {
        &-container {
            .label {
                margin-bottom: f.px2rem(4);

                color: v.$blackish-60;
            }
        }
    }

    .cot-composition-charts {
        @include m.grid;

        .chart-1,
        .chart-2 {
            grid-column: 1/-1;
        }

        .chart-1 {
            @include m.screen(sm) {
                grid-column: 1/6;
            }
        }

        .chart-2 {
            @include m.screen(sm) {
                grid-column: 6/13;
            }
        }
    }

    .cot-download-docs {
        .btn-container {
            display: flex;

            flex-direction: column;

            gap: 1rem;

            @include m.screen(sm) {
                flex-direction: row;
            }

            .btn-tertiary {
                display: flex;

                justify-content: center;
            }
        }

        .btn-secondary {
            width: 100%;
            margin-top: f.px2rem(30);

            @include m.screen(sm) {
                width: auto;
                margin-top: f.px2rem(24);
            }
        }
    }

    .badge-tag {
        display: flex;

        gap: .5rem;

        align-items: center;

        padding: .25rem .5rem;

        border-radius: 999px;

        &.--highlighted {
            border: 1px solid v.$blackish;
        }

        .tag {
            text-wrap: nowrap;
        }
    }

    .variation-selectors {
        display: flex;

        gap: 2rem;

        align-items: center;
        justify-content: space-between;

        margin-top: 1rem;
        overflow-x: auto;

        .variation-selector {
            display: flex;

            flex: 1 1 auto;
            flex-direction: column;

            gap: .25rem;

            align-items: center;
            justify-content: center;

            padding: 1rem;

            border-radius: v.$border-radius;
        }

        .medium-text {
            font-weight: 600;
        }

        .--red {
            color: v.$red;
        }

        .--green {
            color: v.$green;
        }

        .--neutral {
            color: v.$blackish;
        }

        .--selected {
            background-color: v.$blackish-10;
        }
    }

    .products-section {
        .title {
            margin-bottom: f.px2rem(40);

            @include m.screen(sm) {
                margin-bottom: f.px2rem(64);
            }
        }

        .splide__track {
            --bg-color: #{v.$white};
        }
    }

    .btn {
        box-shadow: none;
    }

    .ant-tabs-nav {
        border-bottom: 1px solid v.$blackish-10;
    }

    [class*="cols-"]:not(.cols-1)>* {
        opacity: 1 !important;
    }

    .router-link {
        &:hover {
            color: v.$blackish;
        }
    }

    .cot-product-detail-tabs {
        overflow: hidden;
    }

    .cot-chart-container {
        &>.paragraph {
            margin: 2rem 0;

            text-align: center;
        }
    }

    .ticker-logo {
        border-radius: 999px;
    }
}