@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.hero {
    height: 100dvh;

    @include m.screen(sm) {
        // height: v.$hero-desktop-height;
        min-height: f.px2rem(600);
        max-height: 100dvh;
    }

    .flex-content {
        grid-column: 1/-1;

        padding-top: 12rem;

        @include m.screen(sm) {
            padding-top: 14rem;
        }
    }

    .paragraph-hero {
        display: flex;

        gap: .5rem;

        align-items: center;
        justify-content: center;

        width: 100%;

        // width: max-content;

        margin: 1rem auto 0;

        @include m.screen(sm) {
            grid-column: 5/11 !important;
        }
    }

    .text-content {
        display: grid;

        grid-template-columns: repeat(4, minmax(auto, 1fr));

        column-gap: f.px2rem(24);

        @include m.screen(sm) {
            grid-template-columns: repeat(14, minmax(auto, 1fr));

            column-gap: f.px2rem(40);
        }

        &>* {
            grid-column: 1/-1;
        }

        &>.paragraph-hero:first-child {
            margin: 0 auto f.px2rem(40) auto;
        }
    }

    .title-hero {
        margin-bottom: f.px2rem(10);

        @include m.screen(sm) {
            grid-column: 4 / 12;

            margin-bottom: f.px2rem(16)
        }
    }

    .btn {
        margin-top: f.px2rem(40);

        @include m.screen(sm) {
            margin-top: f.px2rem(64)
        }
    }

    [data-stagger] {
        opacity: 0;

        transform: translateY(20px);
    }
}

.hero.--great-hero {
    .gradient {
        background: linear-gradient(to right, var(--hero-color-gradient-start), var(--hero-color-gradient-end));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}