@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.error-page {
    height: 100dvh;
    
    background-color: v.$blackish;

    .component-layout {
        height: 100dvh;
    }

    .title-hero {
        margin-top: 2.5rem;

        @include m.screen(sm) {
            margin-top: 3rem;
        }
    }

    .paragraph-hero {
        margin-top: 16px;
        margin-bottom: 0 !important;

        @include m.screen(sm) {
            grid-column: 4 / 10 !important;
        }
    }

    .tag {
        margin: 0 auto;
    }
}