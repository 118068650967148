@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.table {
    table {
        width: 100%;
        margin: auto;

        border-collapse: collapse;

        thead {
            position: relative;

            background-color: rgb(250, 250, 250);

            tr {
                th {
                    padding: 1.125rem 1rem;

                    font-size: 0.75rem;


                    &:first-child {
                        text-align: left;

                        border-radius: 0.625rem 0 0 0.625rem;
                    }

                    &:last-child {
                        border-radius: 0 0.625rem 0.625rem 0;
                    }

                    &:not(:first-child) {
                        position: relative;

                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 0;

                            width: 1px;
                            height: 22px;

                            content: '';

                            background-color: v.$blackish-5;

                            transform: translateY(-50%);

                        }
                    }
                }
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;

                width: 96%;
                height: 1px;

                content: '';

                background-color: v.$blackish-5;

                transform: translateX(-50%);
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid v.$blackish-5;

                td {
                    padding: 1rem;

                    font-size: 0.75rem;
                    color: v.$blackish-70;

                    &:not(:first-child) {
                        text-align: center;
                    }
                }
            }
        }
    }

    &.--narrow {
        grid-column: 1 / -1 !important;

        overflow-x: scroll;

        @include m.screen("md") {
            grid-column: 3 / 11 !important;

            overflow-x: unset;
        }
    }
}