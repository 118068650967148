/* stylelint-disable scss/at-if-closing-brace-space-after */
/* stylelint-disable scss/at-if-closing-brace-newline-after */
/* stylelint-disable scss/double-slash-comment-empty-line-before */
/* stylelint-disable scss/at-else-empty-line-before */
/* stylelint-disable at-rule-empty-line-before */

@use "sass:math";

@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

$spacing-sizes-lowerthansm: (
    0: 0,
    0_5: calc(0.5rem / 2),
    1: calc(1rem / 2),
    1_5: calc(1.5rem / 2),
    2: calc(2rem / 2),
    3: calc(3rem / 2),
    4: calc(4rem / 2),
    8: calc(8rem / 2),
    10: calc(10rem / 2),
    15: calc(15rem / 2),
);
$spacing-sizes-biggerthansm: (
    0: 0,
    0_5: 0.5rem,
    1: 1rem,
    1_5: 1.5rem,
    2: 2rem,
    3: 3rem,
    4: 4rem,
    8: 8rem,
    10: 10rem,
    15: 15rem,
);
$breakpoints: (
    lowerthansm: "(max-width: 639px)",
    biggerthansm: "(min-width: 640px)",
);

// Responsive utility classes
@each $breakpoint-name, $breakpoint-value in $breakpoints {
    @media #{$breakpoint-value} {
        @if $breakpoint-name =="lowerthansm" {
            @each $size-key, $size-value in $spacing-sizes-lowerthansm {

                // Responsive Margin utilities
                .m-#{$size-key} {
                    margin: $size-value !important;
                }

                .mt-#{$size-key} {
                    margin-top: $size-value !important;
                }

                .mr-#{$size-key} {
                    margin-right: $size-value !important;
                }

                .mb-#{$size-key} {
                    margin-bottom: $size-value !important;
                }

                .ml-#{$size-key} {
                    margin-left: $size-value !important;
                }

                .mx-#{$size-key} {
                    margin-right: $size-value !important;
                    margin-left: $size-value !important;
                }

                .my-#{$size-key} {
                    margin-top: $size-value !important;
                    margin-bottom: $size-value !important;
                }

                // Responsive Padding utilities
                .p-#{$size-key} {
                    padding: $size-value !important;
                }

                .pt-#{$size-key} {
                    padding-top: $size-value !important;
                }

                .pr-#{$size-key} {
                    padding-right: $size-value !important;
                }

                .pb-#{$size-key} {
                    padding-bottom: $size-value !important;
                }

                .pl-#{$size-key} {
                    padding-left: $size-value !important;
                }

                .px-#{$size-key} {
                    padding-right: $size-value !important;
                    padding-left: $size-value !important;
                }

                .py-#{$size-key} {
                    padding-top: $size-value !important;
                    padding-bottom: $size-value !important;
                }
            }
        }

        @else if $breakpoint-name =="biggerthansm" {
            @each $size-key, $size-value in $spacing-sizes-biggerthansm {

                // Responsive Margin utilities
                .m-#{$size-key} {
                    margin: $size-value !important;
                }

                .mt-#{$size-key} {
                    margin-top: $size-value !important;
                }

                .mr-#{$size-key} {
                    margin-right: $size-value !important;
                }

                .mb-#{$size-key} {
                    margin-bottom: $size-value !important;
                }

                .ml-#{$size-key} {
                    margin-left: $size-value !important;
                }

                .mx-#{$size-key} {
                    margin-right: $size-value !important;
                    margin-left: $size-value !important;
                }

                .my-#{$size-key} {
                    margin-top: $size-value !important;
                    margin-bottom: $size-value !important;
                }

                // Responsive Padding utilities
                .p-#{$size-key} {
                    padding: $size-value !important;
                }

                .pt-#{$size-key} {
                    padding-top: $size-value !important;
                }

                .pr-#{$size-key} {
                    padding-right: $size-value !important;
                }

                .pb-#{$size-key} {
                    padding-bottom: $size-value !important;
                }

                .pl-#{$size-key} {
                    padding-left: $size-value !important;
                }

                .px-#{$size-key} {
                    padding-right: $size-value !important;
                    padding-left: $size-value !important;
                }

                .py-#{$size-key} {
                    padding-top: $size-value !important;
                    padding-bottom: $size-value !important;
                }
            }
        }
    }
}

.--text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.--hidden {
    visibility: hidden !important;
}

.--mobile-only {
    display: block !important;

    @include m.screen(sm) {
        display: none !important;
    }
}

.--desktop-only {
    display: none !important;

    @include m.screen(sm) {
        display: block !important;
    }
}

.--below-895 {
    display: block !important;

    @media (min-width: (895px)) {
        display: none !important;
    }
}

.--below-895-flex {
    display: flex !important;

    @media (min-width: (895px)) {
        display: none !important;
    }
}

.--above-895 {
    display: none !important;

    @media (min-width: (895px)) {
        display: flex !important;
    }
}

.--above-895-grid {
    display: none !important;

    @media (min-width: (895px)) {
        display: grid !important;
    }
}

.--above-895-flex {
    display: none !important;

    @media (min-width: (895px)) {
        display: flex !important;
    }
}

.--visibility-hidden {
    visibility: hidden !important;
}

.--card-height-sm {
    height: f.px2rem(166) !important;

    @include m.screen(sm) {
        height: f.px2rem(240) !important;
    }
}

.--card-height-md {
    height: f.px2rem(222) !important;

    @include m.screen(sm) {
        height: f.px2rem(320) !important;
    }
}

.--card-height-lg {
    height: f.px2rem(322) !important;

    @include m.screen(sm) {
        height: f.px2rem(466) !important;
    }
}

.--card-height-xl {
    height: f.px2rem(360) !important;

    @include m.screen(sm) {
        height: f.px2rem(515) !important;
    }
}

.--fullwidth-height-xs {
    height: f.px2rem(140) !important;

    @include m.screen(sm) {
        height: f.px2rem(240) !important;
    }
}

.--fullwidth-height-sm {
    height: f.px2rem(240) !important;

    @include m.screen(sm) {
        height: f.px2rem(400) !important;
    }
}

.--fullwidth-height-md {
    height: f.px2rem(240) !important;

    @include m.screen(sm) {
        height: f.px2rem(480) !important;
    }
}

.--fullwidth-height-lg {
    height: f.px2rem(240) !important;

    @include m.screen(sm) {
        height: f.px2rem(640) !important;
    }
}

.--variation-up {
    color: #269532 !important;
}

.--variation-down {
    color: #FF4D4F !important;
}

.--variation-none {
    color: #1673FE !important;
}

.--no-scroll {
    height: 100% !important;
    overflow: hidden !important;
}