@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.text-feature {
    position: relative;

    --padding-left: 1.5rem;

    .title,
    .content {
        padding-left: var(--padding-left);

        border-left: 1px solid red;
    }

    .content {
        display: flex;

        flex-direction: column;

        justify-content: space-between;

        padding-top: 1rem;

        .text-link {
            margin-top: 1.5rem;
            
            letter-spacing: -0.13px;
        }
    }
}