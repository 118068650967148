@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.horizontal-text-image {
    @include m.grid;

    row-gap: 3rem;

    @include m.screen(sm) {
        row-gap: f.px2rem(30);
    }

    img,
    video,
    .content {
        @include m.screen(sm) {
            grid-row: 1;
        }
    }

    img,
    video {
        grid-column: 1/-1;

        width: 100%;

        border-radius: v.$border-radius;
    }

    .content {
        display: flex;

        flex-direction: column;

        grid-column: 1/-1;

        align-items: flex-start;
        justify-content: center;

        // For animations
        > * {
            opacity: 0;

            transform: translate(0, 20px);

            // &:not([data-split]) {
            //     transform: translate(0, 20px);
            // }

            // .line-parent {
            //     overflow: hidden;

            //     [class*="line-child-"] {
            //         opacity: 0;
            //     }
            // }
        }
    }

    &:not(.--content-reverse) {
        img, video {
            @include m.screen(sm) {
                grid-column: 7 / span 6;
            }
        }

        .content {
            @include m.screen(sm) {
                grid-column: 1 / span 6;
            }
        }
    }

    &.--content-reverse {
        img, video {
            @include m.screen(sm) {
                grid-column: 1 / span 6;
            }
        }

        .content {
            @include m.screen(sm) {
                grid-column: 7 / span 6;
            }
        }
    }

    .tag,
    .title-1 {
        margin-bottom: f.px2rem(10);

        @include m.screen(sm) {
            margin-bottom: 1rem;
        }
    }

    .tag {
        display: block;
    }

    .paragraph {
        margin-bottom: f.px2rem(20);

        @include m.screen(sm) {
            margin-bottom: 2rem;
        }
    }
}