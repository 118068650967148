@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../base/fonts" as fonts;

.btn {
    display: inline-block;

    padding: 1rem 1.5rem;

    text-align: center;

    cursor: pointer;

    background-color: transparent;
    border-radius: f.px2rem(40);

    @include m.screen(v.$sm) {
        padding: 1.25rem 2rem;

        transition: all 0.2s ease-in-out;
    }

    @include m.drop-shadow;

    &-primary {
        @include fonts.button-primary-font-style;
    }

    &-secondary {
        @include fonts.button-secondary-font-style;
    }

    &-tertiary {
        display: flex;

        gap: .5rem;

        align-items: center;

        padding: f.px2rem(10) f.px2rem(16);

        @include fonts.button-tertiary-font-style;

        svg {
            width: f.px2rem(6);
        }

        @include m.screen(sm) {
            &:hover {
                transform: none;
            }
        }
    }

    &-link {
        padding: 0;

        @include m.screen(sm) {
            &:hover {
                transform: none;
            }
        }
    }
}

.chevron {
    position: relative;

    display: flex;

    align-items: center;
    justify-content: center;

    width: f.px2rem(32);
    height: f.px2rem(32);
    padding: .5rem;

    border-radius: 999px;
}

.cover-link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    border-radius: 0;
    box-shadow: none;

    &:focus-visible {
        outline: none;
    }
}

.pill {
    padding: .5rem 1rem;

    background-color: v.$blackish-20;
    border-radius: 999px;
}

.pill-border {
    display: flex;

    gap: .5rem;

    align-items: center;
    justify-content: center;

    width: max-content;
    padding: f.px2rem(6) f.px2rem(12);

    text-transform: uppercase;

    border-radius: 999px;

    &.--type-default {
        // background-color: v.$white;
        border: 1px solid v.$blackish-20;
    }

    &.--type-highlighted {
        background-color: v.$white;
        border: 1px solid v.$blackish-20;
    }
}

.filter-btn {
    margin: auto 0;

    background-color: transparent;
}

.filter-btn,
.filter-pill {
    white-space: nowrap;
}

.filter-btn.with-values {
    text-decoration: underline;

    cursor: pointer;
}

.filter-pill {
    padding: f.px2rem(14);

    color: v.$blackish;
    text-transform: uppercase;

    cursor: pointer;

    border-radius: 999px;

    transition: v.$transition;
}

.btn-link {
    text-decoration: underline;

    @include m.screen(sm) {
        &:hover {
            color: v.$blackish-60;
            text-decoration: underline;
        }
    }
}