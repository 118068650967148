/* stylelint-disable rule-empty-line-before */
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.footer {

    .block-1,
    .block-2,
    .block-3,
    .block-4 {
        @include m.grid;

        grid-column: 1/-1;
    }

    .paragraph {
        a {
            color: v.$white;
            text-decoration: none;
        }
    }

    .locale-link {
        position: relative;

        .safari-caret {
            right: 2px;
        }
    }

    .block-1 {
        &>* {
            grid-column: 1/-1;
        }

        .contact {
            grid-column: 1/5;
        }

        .nav-block {
            grid-column: span 2;

            .divider {
                margin: 2rem 0;
            }
        }

        .phone {
            margin: 1rem 0;
        }

        .paragraph {
            a {
                transition: v.$transition;

                &:hover {
                    color: v.$white-50;
                }
            }
        }

        .nav-block {
            h4.paragraph {
                margin-bottom: 1.5rem;

                font-weight: 500;
            }

            ul {
                display: flex;

                flex-direction: column;

                gap: 0.5rem;

                /* stylelint-disable no-descending-specificity */
                li {
                    a {
                        transition: v.$transition;

                        &:hover {
                            color: v.$white !important;
                        }

                        &.disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }
                }

                /* stylelint-enable no-descending-specificity */
            }
        }

        .accordion-nav {
            margin-top: f.px2rem(38);
            margin-bottom: f.px2rem(80);
        }

        .accordion__container__content {
            gap: 0;
        }

        .accordion__visible-part {
            padding: f.px2rem(12) 0 f.px2rem(12) 0;

            border-top: 1px v.$white-20 solid;
            border-radius: 0;

            .panel {
                ul {
                    display: flex;

                    flex-direction: column;

                    gap: 0.25rem;
                }
            }

            .chevron {
                svg {
                    width: 10px;

                    path {
                        stroke: v.$white;
                    }
                }
            }
        }
    }

    .block-2 {
        display: flex;

        justify-content: space-between;

        padding-bottom: f.px2rem(20);
        margin-top: f.px2rem(80);

        @include m.screen(sm) {
            padding-bottom: f.px2rem(32);
            margin-top: f.px2rem(128);
        }

        .social {
            display: flex;

            gap: f.px2rem(20);

            /* stylelint-disable no-descending-specificity */
            a {
                &:hover {
                    svg {
                        path {
                            fill-opacity: 1;
                        }
                    }
                }
            }

            /* stylelint-enable no-descending-specificity */
        }
    }

    .block-3 {
        align-items: start;

        padding-top: f.px2rem(30);

        @include m.screen(sm) {
            padding-top: f.px2rem(60);
        }

        &>* {
            grid-column: 1/-1;
        }

        .locale {
            display: flex;

            gap: f.px2rem(24);

            margin-bottom: f.px2rem(32);

            @include m.screen(sm) {
                grid-column: 1/5;

                margin-bottom: 0;
            }

            &-link {
                display: flex;

                gap: .5rem;

                align-items: center;

                transition: v.$transition;
            }

            .select-container {
                position: relative;
                width: 200px;
                cursor: pointer;
            }

            .selected-option {
                padding: 10px;
                border-radius: v.$border-radius;
                color: v.$white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 8px;

                p {
                    font-size: 14px;
                    font-weight: 600;
                }

                svg {
                    margin-left: 10px;
                }

                &.country {
                    background-image: url("../images/arg.png");
                    background-repeat: no-repeat;
                    background-position: left;
                    background-size: 20px;
                    padding-left: 30px;
                }

                &.language {
                    background-image: url("../images/icons/icon-world.svg");
                    background-repeat: no-repeat;
                    background-position: left;
                    background-size: 20px;
                    padding-left: 30px;
                }
            }

            .dropdown-options {
                position: absolute;
                width: 100%;
                top: 100%;
                left: 0;
                border-radius: v.$border-radius;
                background-color: #fff;
                z-index: 100;
                overflow: hidden;
                color: v.$blackish;
                max-height: 0;
                transition: v.$transition;
                padding: 0 8px;
            }

            .dropdown-options input[type="radio"] {
                display: none;
            }

            .dropdown-options label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                background-color: #fff;
                cursor: pointer;
                transition: background-color 0.3s ease;
                border-radius: v.$border-radius;
                font-size: 14px;
                font-weight: 600;

                &.country {
                    background-image: url("../images/arg.png");
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: 8px;
                    padding-left: 38px;
                }

                &.country-uy {
                    background-image: url("../images/uy.png");
                    background-repeat: no-repeat;
                    background-position: 8px;
                    background-size: 20px;
                    padding-left: 38px;
                }
            }

            .dropdown-options label:hover {
                background-color: v.$blackish-10
            }

            .select-container.open .dropdown-options {
                max-height: 200px;
                padding: 8px;
            }

            .checkmark {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                display: none;
            }

            input[type="radio"]:checked + label .checkmark {
                display: inline-block;
            }
        }

        .legal {

            @include m.screen(sm) {
                grid-column: 5/13;
            }

            /* stylelint-disable no-descending-specificity */
            ul {
                display: flex;

                flex-wrap: wrap;

                gap: 0.5rem 1rem;
            }

            /* stylelint-enable no-descending-specificity */

            .small-text {
                transition: v.$transition;
            }

            .external-link {
                display: inline-flex;

                gap: f.px2rem(2);

                align-items: center;

                /* stylelint-disable no-descending-specificity */
                svg {
                    opacity: 0.5;

                    transition: v.$transition;
                }

                /* stylelint-enable no-descending-specificity */

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }

        .afip {
            margin-top: f.px2rem(48);

            @include m.screen(sm) {
                grid-column: 5/11;
            }

            .content-box:nth-of-type(2) {
                margin-top: 1rem;
            }

        }

        .qr {
            display: flex;

            gap: 1rem;

            justify-content: space-evenly;

            margin-top: f.px2rem(64);

            /* stylelint-disable-next-line no-descending-specificity */
            a {
                width: 30%;
            }

            img {
                width: 100%;

                pointer-events: none;
            }

            @include m.screen(sm) {
                grid-row: 2/3;
                grid-column: 11/13;

                justify-content: start;

                width: 100%;
                margin-top: f.px2rem(48);

                a {
                    width: 100%;
                }
            }
        }
    }

    .block-4 {
        &>* {
            grid-column: 1/-1;
        }

        margin-top: f.px2rem(80);

        @include m.screen(sm) {
            margin-top: f.px2rem(128);
        }

        /* stylelint-disable-next-line no-descending-specificity */
        ul {
            display: flex;

            flex-wrap: wrap;

            gap: 1rem;

            align-items: center;
            justify-content: space-evenly;

            width: 100%;
        }
    }
}