@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

.image-video {
    position: relative;

    background-position: center;
    background-size: cover;

    &.--gradient {
        @include m.gradient(var(--bg-gradient-color), var(--gradient-direction), var(--gradient-opacity), var(--gradient-size))
    }
}

.image-video-el {
    display: block;

    width: 100%;
    // max-height: 400px;
    object-fit: cover;
}