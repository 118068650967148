@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/variables" as v;

// @include m.grid;

.news-page-header {
    @include m.grid;

    position: relative;

    row-gap: 1.5rem;

    padding-top: 9.25rem;
    padding-bottom: 2.5rem; 

    border-bottom: 1px solid v.$blackish-10;

    @include m.screen("md") {
        padding-top: 15rem;
        padding-bottom: 4rem;
    }

    &__bg-image {
        position: absolute;
        left: 0;
        z-index: 0;

        width: 100%;

        filter: blur(200px);
        mix-blend-mode: exclusion;
        opacity: 0.4;

        @include m.screen("md") {
            left: 52%;

            width: 50%;
        }
    }

    &__content {
        z-index: 1;

        display: flex;

        flex-direction: column;

        grid-column: 1 / -1;

        gap: f.px2rem(30);

        justify-content: center;

        .tag {
            width: fit-content;
            padding: f.px2rem(6) f.px2rem(10);

            border: 1px solid v.$blackish-10;
            border-radius: 20px;
        }

        &-author {
            color: v.$blackish-70;
        }

        @include m.screen("md") {
            grid-column: 1 / 6;

            gap: 3rem;
        }
    }

    &__image {
        z-index: 1;
        
        grid-column: 1 / -1;

        overflow: hidden;

        border-radius: 12px;

        img {
            object-fit: cover;

            width: 100%;
            aspect-ratio: 8 / 5;
        }

        @include m.screen("md") {
            grid-column: 6 / -1;
        }
    }
}

.news-page-related {
    margin-bottom: 2.5rem;

    @include m.screen("md") {
        margin-bottom: 4rem;
    }
}